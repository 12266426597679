@import "../../../styles/variables";
@import "../../../styles/mixins/breakpoint";

.input {
  font-family: $font-family-primary;
  padding: $input-padding-vertical $input-padding-horizontal;
  background: none;
  background-color: $input-background-color;
  border: 1px solid $input-border-color;
  border-radius: 5px;
  font-size: $input-font-size;
  line-height: 1.3;
  color: $input-color;

  @include tablet-large {
    max-width: 100%;
  }

  &::placeholder {
    color: $placeholder-color;
  }

  &:hover {
    border-color: $input-border-color-hover;
  }

  &:focus {
    border-color: $input-border-color-focus;
  }

  &:active {
    border-color: $input-border-color-focus;
  }

  &.error {
    border-color: $input-error-border-color;
  }

  &.fullwidth {
    width: 100%;
  }

  &:disabled {
    background-color: $input-disabled-background-color;
    border-color: $input-disabled-border-color;
  }
}

input.input-primary {
  border-color: $primary-color;

  + [class^="icon-"] {
    background-color: $primary-color;
    color: $color-white;
  }
}

.input-item {
  position: relative;
  margin-bottom: 10px;

  &.input-icon-left {
    .input {
      padding-left: $input-icon-width;
    }

    [class^="icon-"] {
      left: 0;
      border-top-left-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
    }
  }

  &.icon-right {
    .input {
      padding-right: $input-icon-width;
    }

    [class^="icon-"] {
      right: 0;
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
    }
  }

  [class^="icon-"] {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $input-icon-width;
    height: 100%;
    font-size: 16px;

    @include tablet-large {
      font-size: 20px;
    }

    @include desktop-md {
      font-size: 24px;
    }
  }

  .input-toggle-password {
    position: absolute;
    top: 0;
    right: 0;
    width: $input-icon-width;
    height: 100%;
    padding: 10px;
    font-size: 18px;
    background: none;

    [class^="icon-"] {
      font-size: 14px;
    }

    .icon-eye-close {
      font-size: 12px;
    }
  }
}

.label {
  display: block;
  margin-bottom: 2px;
  font-weight: 600;
  font-size: 12px;
  color: $label-color;
}

.label-inline {
  font-size: 10px;
  margin-bottom: 8px;

  @include tablet-large {
    margin-bottom: 0;
    padding: 12px 0;
    font-size: 14px;
  }
}
