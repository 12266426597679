@import "../../../styles/variables";
@import "../../../styles/mixins/breakpoint";

$radio-border-width: 1px;
$radio-size: 16px;
$radio-background-color: $primary-color;
$radio-color: #d5e3eb;
$radio-color-focus: $text-color;

.radio {
  display: flex;
  min-height: 19px;
  line-height: 1.5;
  cursor: pointer;

  &.radio-disabled {
    pointer-events: none;
  }
}

.radio-input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);

  + .radio-box {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: $radio-size;
    height: $radio-size;
    padding: 5px;
    font-size: 18px;
    color: inherit;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      flex-shrink: 0;
      width: $radio-size;
      height: $radio-size;
      margin-right: 12px;
      overflow: hidden;
      border-radius: 50%;
      background-color: transparent;
      box-shadow: inset 0 0 0 1px $radio-color;

      .radio-inverse & {
        background-color: transparent;
      }
    }

    &:before {
      content: "";
      display: none;
      z-index: 1;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $color-white;
    }
  }

  &:checked {
    + .radio-box {
      &:after {
        background-color: $radio-background-color;
        border-color: $primary-color;
        box-shadow: inset 0 0 0 1px $primary-color;

        .radio-inverse & {
          background-color: $color-white;
          border-color: $radio-color;
        }
      }

      &:before {
        display: block;

        .radio-inverse & {
          background-color: #4a4d57;
        }
      }
    }
  }

  &:hover {
    + .radio-box {
      &:after {
        border-color: $input-border-color-hover;
      }
    }
  }

  &:focus {
    + .radio-box {
      &:after {
        box-shadow: 0 0 6px $box-shadow;
      }
    }
  }

  &:disabled {
    + .radio-box {
      &::after {
        background-color: $input-disabled-background-color;
      }
    }
  }
}

.radio-label {
  padding-left: 26px;
  font-size: 13px;
}
