@font-face {
  font-family: "montserratmedium";
  src: url("/assets/fonts/montserrat-medium-webfont.woff2") format("woff2"),
    url("/assets/fonts/montserrat-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratbold";
  src: url("/assets/fonts/montserrat-bold-webfont.woff2") format("woff2"),
    url("/assets/fonts/montserrat-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratlight";
  src: url("/assets/fonts/montserrat-light-webfont.woff2") format("woff2"),
    url("/assets/fonts/montserrat-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "signikaregular";
  src: url("/assets/fonts/signika-regular-webfont.woff2") format("woff2"),
    url("/assets/fonts/signika-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "signikasemibold";
  src: url("/assets/fonts/signika-semibold-webfont.woff2") format("woff2"),
    url("/assets/fonts/signika-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans-light.woff2") format("woff2"),
    url("/assets/fonts/opensans-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans-regular.woff2") format("woff2"),
    url("/assets/fonts/opensans-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans-semibold.woff2") format("woff2"),
    url("/assets/fonts/opensans-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans-bold.woff2") format("woff2"),
    url("/assets/fonts/opensans-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans-extrabold.woff2") format("woff2"),
    url("/assets/fonts/opensans-extrabold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Yeseva One";
  src: url("/assets/fonts/yeseva-one-regular.woff2") format("woff2"),
    url("/assets/fonts/yeseva-one-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "icomoon";
  src: url("/assets/fonts/icomoon.eot?95vdx7");
  src: url("/assets/fonts/icomoon.eot?95vdx7#iefix") format("embedded-opentype"),
    url("/assets/fonts/icomoon.ttf?95vdx7") format("truetype"),
    url("/assets/fonts/icomoon.woff?95vdx7") format("woff"),
    url("/assets/fonts/icomoon.svg?95vdx7#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-full-screen:before {
  content: "\e925";
  color: #fff;
}
.icon-like-fill:before {
  content: "\e920";
  color: #e43373;
}
.icon-like:before {
  content: "\e924";
  color: #888c9d;
}
.icon-wechat .path1:before {
  content: "\e91a";
  color: rgb(0, 211, 50);
}
.icon-wechat .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wechat .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-facebook:before {
  content: "\e91d";
  color: #1777f2;
}
.icon-linkedin .path1:before {
  content: "\e91e";
  color: rgb(0, 123, 181);
}
.icon-linkedin .path2:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-filter:before {
  content: "\e921";
}
.icon-menu:before {
  content: "\e922";
}
.icon-logout:before {
  content: "\e923";
}
.icon-add-circled-outline:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-bell:before {
  content: "\e902";
}
.icon-bell-notification:before {
  content: "\e903";
}
.icon-big-arrow:before {
  content: "\e904";
}
.icon-check:before {
  content: "\e905";
}
.icon-chevron-down:before {
  content: "\e906";
}
.icon-chevron-down-light:before {
  content: "\e907";
}
.icon-chevron-left-light:before {
  content: "\e908";
}
.icon-chevron-right:before {
  content: "\e909";
}
.icon-chevron-right-light:before {
  content: "\e90a";
}
.icon-chevron-up:before {
  content: "\e90b";
}
.icon-chevron-up-light:before {
  content: "\e90c";
}
.icon-download:before {
  content: "\e90d";
}
.icon-edit:before {
  content: "\e90e";
}
.icon-eye:before {
  content: "\e90f";
}
.icon-play:before {
  content: "\e910";
}
.icon-plus:before {
  content: "\e911";
}
.icon-search:before {
  content: "\e912";
}
.icon-star:before {
  content: "\e913";
}
.icon-star-fill:before {
  content: "\e914";
}
.icon-star-cross:before {
  content: "\e920";
}
.icon-trash:before {
  content: "\e915";
}
.icon-triangle-down:before {
  content: "\e916";
}
.icon-upload:before {
  content: "\e917";
}
.icon-x:before {
  content: "\e918";
}
.icon-x-circle:before {
  content: "\e919";
}

@font-face {
  font-family: "Glyphter";
  src: url("/assets/fonts/Glyphter.eot");
  src: url("/assets/fonts/Glyphter.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Glyphter.woff") format("woff"),
    url("/assets/fonts/Glyphter.ttf") format("truetype"),
    url("/assets/fonts/Glyphter.svg#Glyphter") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class*="icon-Vector"]:before {
  display: inline-block;
  font-family: "Glyphter";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-Vector:before {
  content: "\0041";
}
