@import "../../../styles/variables.scss";
@import "../../../styles/mixins/breakpoint";

.rodal {
  display: flex;
  align-items: center;
  padding-top: 75px;
  padding-right: $container-padding-horizontal-mobile;
  padding-bottom: 75px;
  padding-left: $container-padding-horizontal-mobile;
  overflow: auto;
  background: rgba(0, 0, 0, 0.3);

  h2 {
    font-size: 24px;
    margin-bottom: 32px;
  }

  video {
    width: 100%;
    background-color: black;
    object-fit: contain;

    @include tablet {
      max-height: 80vh;
    }
  }
}

.rodal-dialog {
  position: relative;
  max-width: 100%;
  height: auto !important;
  padding: 32px 16px;
  border-radius: 6px;

  form {
    max-width: 460px;
    margin-right: auto;
    margin-left: auto;
  }

  .modal-icon {
    display: block;
    width: 42px;
    height: 42px;
    font-size: 50px;
    margin-right: auto;
    margin-bottom: 32px;
    margin-left: auto;
    color: $primary-color; // default modal icon color

    &:before {
      position: relative;
      top: -4px;
      left: -4px;
    }
  }

  .input-item {
    margin-bottom: 10px;

    &.input-item--textarea {
      margin-bottom: 20px;
    }
  }

  h3,
  p {
    font-size: 18px;
  }

  ul,
  ol {
    margin-bottom: 18px;
  }

  @include tablet {
    padding: 32px;
  }
}

.rodal-mask {
  background: none;
}

.modal-video {
  .rodal-close {
    top: 13px;
    right: 13px;

    &:before,
    &:after {
      background: $color-white;
    }
  }
  .rodal-dialog {
    padding: 0;
    border-radius: 0;
    background: none;
    box-shadow: none;
  }
}
