@import "../../../styles/mixins/breakpoint";
@import "../../../styles/variables.scss";

.image-slider {
  padding-bottom: 56px;

  @include tablet {
    padding-bottom: 0;
  }
}

.modal-image-slider {
  padding-bottom: 56px;

  .rodal-close {
    display: none;
  }

  .rodal-dialog {
    padding: 8px;
    background: none;
    box-shadow: none;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: flex;
    width: 40px;
    height: 40px;
    background-color: $color-white;
    top: auto;
    bottom: 0;
    padding: 5px 10px;

    @include tablet {
      top: 50%;
      transform: translateY(-50%);
    }

    &:before {
      color: $primary-color;
    }
  }

  .swiper-button-next {
    right: 0;
    &.swiper-button-disabled{
      display: none !important;
    }
  }

  .swiper-button-prev {
    left: 0;
    &.swiper-button-disabled{
      display: none !important;
    }
  }
}

.modal-image {
  width: 100%;
  height: 464px;
  border: 8px solid #ffffff;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  background-color: #ffffff;

  @include tablet {
    width: 546px;
    min-height: 672px;
    margin: 0 auto;
  }
}

.modal-slide-video {
  display: flex;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;

  video {
    border: 8px solid #ffffff;
    background-color: #ffffff;
    height: 464px;

    @include tablet {
      height: 500px;
    }
  }


  @include tablet {
    width: 546px;
    margin: 0 auto;
  }
}
.image-swiper-button-next,
.image-swiper-button-prev{
  display: none !important;
}

.image-swiper-button-next.swiper-button-disabled,
.image-swiper-button-prev.swiper-button-disabled{
  display: none !important;
}