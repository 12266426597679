@import "./variables.scss";
@import "../../node_modules/bootstrap/scss/_functions.scss";
@import "../../node_modules/bootstrap/scss/_variables.scss";
@import "../../node_modules/bootstrap/scss/_mixins.scss";
@import "../../node_modules/bootstrap/scss/_utilities.scss";
@import "../../node_modules/bootstrap/scss/mixins/_caret.scss";
@import "../../node_modules/bootstrap/scss/_dropdown.scss";
@import "../../node_modules/bootstrap/scss/_nav.scss";
@import "../../node_modules/bootstrap/scss/_transitions.scss";

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  padding: 0;
  border-radius: 6px;
  min-width: auto;
}

.dropdown-icon {
  .dropdown-toggle::after {
    display: none;
  }
}

.tabs {
  border-bottom: none;

  .nav-link,
  .nav-link.active {
    background: none;
    border: 0;
    cursor: pointer;
  }

  .nav-link {
    font-size: 16px;
    margin-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: #5e6775;
  }

  .nav-link.active {
    position: relative;
    font-weight: 700;
    color: $color-primary;

    &:before {
      content: "";
      position: absolute;
      right: 0;
      bottom: -6px;
      left: 0;
      width: 5px;
      height: 5px;
      margin-right: auto;
      margin-left: auto;
      border-radius: 50%;
      background-color: $color-primary;
    }
  }
}

.select__control.select__control--is-focused {
  &:hover {
    border-color: $input-border-color-focus;
    box-shadow: none;
  }
}

.multi-select {
  .select__multi-value {
    align-items: center;
    border-radius: 4px;
    margin: 0 3px;
    padding-right: 8px;
  }

  .select__multi-value__label {
    padding-right: 8px;
    padding-left: 8px;
    text-transform: uppercase;
    color: $color-black;
  }

  .select__multi-value__remove {
    padding-right: 0;
    padding-left: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #fefeff;
    cursor: pointer;

    &:hover {
      background-color: #fefeff;

      svg {
        color: $color-black;
      }
    }

    svg {
      color: $icon-color;
    }
  }

  .select__value-container {
    margin-left: -3px;
  }
}
