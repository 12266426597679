.section-approach-image {
  width: auto;
  height: 450px;
  margin-right: -15px;
  margin-left: -15px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  @include tablet {
    width: 843px;
    height: 450px;
    margin-right: 0;
    margin-left: 0;
  }
}

.section-approach-inner {
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.section-approach-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;

  @include tablet {
    width: 70%;
    padding: 0 30px;
  }

  @include tablet-large {
    padding-left: 130px;
  }

  @include desktop {
    width: 40%;
    padding-left: 70px;
  }

  .h2,
  p {
    max-width: 330px;
  }

  p {
    letter-spacing: -0.3px;
  }
}

.section-hero-public {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: auto;
  margin-left: auto;
  min-height: 100vh;
  background-image: url("/assets/images/public-hero-mobile.jpg");

  @include tablet {
    display: block;
    min-height: 800px;
    padding-top: 370px;
    background-image: url("/assets/images/public-hero.jpg");
  }

  &:before {
    display: none;

    @include tablet {
      display: block;
    }
  }
}

.section-hero-image-text {
  z-index: 1;
  position: relative;
  margin-bottom: 16px;
  max-width: 290px;

  @include tablet {
    max-width: 100%;
    margin-bottom: 32px;
  }
}

.section-cards {
  .h2 {
    max-width: 660px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 24px;
    text-align: center;
    letter-spacing: -0.5px;

    @include tablet {
      letter-spacing: -0.9px;
      margin-bottom: 50px;
    }
  }
}

.section-cards-images {
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  > * {
    width: 25%;
    height: 132px;
    padding-right: 4px;
    padding-left: 4px;

    @include tablet {
      padding-right: 9px;
      padding-left: 9px;
      height: 442px;
    }
  }

  img {
    width: 100%;
    height: inherit;
    object-fit: cover;
  }
}

.section-hero-header {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
}

.section-hero-header-logo {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  margin-right: auto;
  margin-top: auto;
  margin-left: auto;
  margin-bottom: auto;

  @include tablet {
    position: static;
    margin-right: 50px;
    margin-left: 0;
  }

  .header-mini &,
  .header-public & {
    @include tablet {
      position: absolute;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.button.section-hero-sign-in {
  display: none;

  @include tablet {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 65px;
    margin-top: 0;
  }
}

.button.section-hero-sign-in-mobile {
  width: auto;

  @include tablet {
    display: none;
  }
}

.section-knowledge {
  padding-top: 0;
  padding-bottom: 0;

  @include tablet {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-knowledge-text-col {
  order: -1;
  padding-bottom: 50px;

  @include tablet {
    order: 1;
    padding-bottom: 0;
  }
}

.section-knowledge-image {
  height: 476px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  margin-right: -15px;
  margin-left: -15px;

  @include tablet {
    margin-right: 0;
    margin-left: 0;
  }
}

.section-knowledge-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 440px;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 0;

  @include tablet {
    padding-left: 30px;
    padding-bottom: 50px;
  }

  p {
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 1.6;
    margin-bottom: 32px;
  }
}

.section-tool {
  .h2 {
    margin-bottom: 24px;

    @include tablet {
      margin-bottom: 48px;
    }
  }
}

.section-tool-image {
  width: 100%;
  height: 397px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;

  @include tablet {
    height: 516px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
