@import "../../../styles/variables";
@import "../../../styles/mixins/breakpoint";

$checkbox-border-width: 1px;
$checkbox-size: 16px;
$checkbox-background-color: $primary-color;
$checkbox-color: #d5e3eb;
$checkbox-color-focus: $text-color;

.checkbox {
  display: flex;
  align-items: flex-start;
  min-height: 19px;
  line-height: 1.5;
  cursor: pointer;

  &.checkbox-disabled {
    pointer-events: none;
  }
}

.checkbox-input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);

  + .checkbox-box {
    display: flex;
    align-items: flex-start;
    position: relative;
    font-size: 18px;
    color: inherit;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      flex-shrink: 0;
      width: $checkbox-size;
      height: $checkbox-size;
      margin-right: 12px;
      overflow: hidden;
      border-radius: 2px;
      background-color: $color-white;
      border: $checkbox-border-width solid $checkbox-color;

      .checkbox-inverse & {
        background-color: transparent;
      }
    }

    &:before {
      content: "";
      display: none;
      z-index: 1;
      position: absolute;
      top: 2px;
      left: 2px;
      width: 15px;
      height: 15px;
      border-radius: 2px;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1.334 5.667 2.667 2.667 6.666-6.667' stroke='%23ffffff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
    }
  }

  &:checked {
    + .checkbox-box {
      &:after {
        background-color: $checkbox-background-color;
        border-color: $primary-color;

        .checkbox-inverse & {
          background-color: $color-white;
          border-color: $checkbox-color;
        }
      }

      &:before {
        display: block;

        .checkbox-inverse & {
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1.334 5.667 2.667 2.667 6.666-6.667' stroke='%234A4D57' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
      }
    }
  }

  &:hover {
    + .checkbox-box {
      &:after {
        border-color: $input-border-color-hover;
      }
    }
  }

  &:focus {
    + .checkbox-box {
      &:after {
        box-shadow: 0 0 6px $box-shadow;
      }
    }
  }

  &:disabled {
    + .checkbox-box {
      &::after {
        background-color: $input-disabled-background-color;
      }
    }
  }
}

.checkbox-label {
  padding-left: 26px;
  font-size: 13px;
  letter-spacing: 0.4px;
  line-height: 1.2;

  .link {
    font-weight: 400;
  }
}
