@import "../../../styles/variables";
@import "../../../styles/mixins/breakpoint";

.input-agolia-search {
 
  width: 100%;
  min-height: 50px;
  font-size: $input-font-size;
  line-height: 1.3;
  color: $input-color;
  margin-bottom: 12px;


  .ant-select-selector {
    height: 100%;
  }

  @include tablet-large {
    max-width: 100%;
    margin-bottom: 16px;
  }

  @include tablet {
    min-height: 60px;
    margin-bottom: 20px;
  }



  &.fullwidth {
    width: 100%;
  }

  &:disabled {
    background-color: $input-disabled-background-color;
    border-color: $input-disabled-border-color;
  }


  .input {
    height: 100%;
    padding-right: 48px;
    text-overflow: ellipsis;
    display: flex;
    border: none;
    width: 80%;
  }

  &::-ms-expand {
    display: none;
  }

  .agolia-dropdown {
    max-width: 270px;
    padding: 0;
  
    @include tablet {
      max-width: 600px;
      left: 56px !important;
    }
  
    .ant-select-item {
      border-bottom: 1px solid $grey-line;
      border-radius: none;
      color: $grey500;
      font-size: 16px;
      z-index: 2;
  
      @include tablet {
        font-size: 16px;
        padding: 12px 20px;
      }
    }
  }
  
}



.input-agolia-search {
  cursor: auto !important;
  .input-search{
    &__text {
      font-size: 10px;
      width: calc(70% - 24px);
  
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: normal !important;
      word-wrap: break-word;
  
      input {
        cursor: text !important;
      }
  
      @include mobile-xxl {
        font-size: 12px;
      }
      @include tablet {
        font-size: 16px;
      }
    }
  }

  .input-agolia-submit {
    font-size: 18px;
    padding-right: 4px;
    @include mobile-xxl {
      font-size: 20px;
    }
    @include tablet {
      font-size: 24px;
      padding-right: 8px;
    }
  }
}

.ant-select-clear {
  width: 18px !important;
  height: 18px !important;
  transform: translateY(-50%);
  margin-top: 0 !important;
  background-color: $placeholder-color !important;
  border-radius: 50%;
  padding: 1px;

  @include mobile-xxl {
    width: 20px !important;
    height: 20px !important;
  }
  @include tablet {
    width: 24px !important;
    height: 24px !important;
    padding: 2px;
  }

  .input-agolia-clear {
    font-size: 16px;
    color: $color-white;

    @include mobile-xxl {
      font-size: 18px;
    }
    @include tablet {
      font-size: 20px;
    }
  }
}



.input-error-message {
  font-size: 12px;
  line-height: 16px;
  color: $color-danger;
}

.input-agolia-submit {
  font-size: 24px;
  padding: 0;
  background: none;
  border: 0;
  color: #98a0ab;

  @include tablet {
    right: 20px;
  }

}


