@import "../../styles/variables.scss";
@import "../../styles/mixins/breakpoint";

.subheader {
  text-align: center;

  .subheader__logo {
    height: 64px;
    width: 64px;
    margin: 20px auto 0px auto;
  }
  .subheader__title {
    font-family: "montserratbold";
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;

    &.subheader__notclickable {
      a {
        cursor: initial;
      }
    }

    @include for-phone-only {
      margin-top: 20px;
    }
  }

  .subheader__tags {
    text-align: center;
    margin: 10px auto;
    padding-bottom: 10px;
    border-bottom: 1px solid $black;
  }
}

.booth-tags__tag {
  display: inline-block;
  // overflow-wrap: break-word;
}

.banner-booth {
  width: 100%;
  height: 500px;
  // padding-left: 15%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.exhibit-title__infos {
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 15px;
  gap: 8px;
}

.crop-container {
  position: relative;
  width: 100%;
  background: #333;
  height: 400px;
}

@media (max-width: 767px) {
  .exhibit-title__infos {
    flex-direction: column-reverse;
    padding-left: 15px !important;
    padding-right: 15px;
  }

  .exhibit-title__infos {
    padding-left: 0;
    flex-direction: column;
  }

  .crop-container {
    position: relative;
    width: 100%;
    background: #333;
    height: 200px;
  }

  // .exhibit-title__logo {
  //   left: 0;
  // }
}

.cropper-buttons {
    position: absolute;
    top: 20px;
    left: 16px;
    display: flex;
    // gap: 8px;
    height: fit-content;
    width: fit-content;
}

.cropper-buttons label {
  margin-right: 4px;
  margin-left: 4px;
}

@media (max-width: 767px) {
  .cropper-buttons {
    top: unset;
    left: unset;
    bottom: -22px;
    right: 16px;
  }
}

@media (max-width: 1460px) {
  .banner-booth {
    height: 500px;
  }



  // .exhibit-title__infos {
  // }
}

@media (max-width: 1200px) {
  .banner-booth {
    height: 40vw;
  }
}

.exhibit-title__infos > div:first-child {
  flex: 1;
  // padding-top: 35px;
  margin-top: -125px;
}

@media (max-width: 767px) {
  .exhibit-title__infos > div:first-child {
    // padding-top: 15px;
    margin-top: -65px;
  }
}

// .reactEasyCrop_CropArea.reactEasyCrop_CropAreaGrid {
//   width: 578.5px !important;
//   height: 200px !important;
// }

.modal-buttons {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.exhibit-title {
  min-height: 605px;
  // padding-top: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include tablet {
    min-height: 637px;
  }

  .divider {
    margin-top: 20px;
  }

  .text-error {
    font-size: 12px;
    line-height: 16px;
  }

  .exhibit-title__actions {
    > * {
      margin-bottom: 12px;

      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  .exhibit-title__wrap {
    display: block;
    width: 100%;
    max-width: 454px;

    .exhibitor-partner {
      display: flex;
      margin-top: 10px;
      align-items: center;
      
      .partner__background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 60px;
        height: 60px;
        border-radius: 4px;
        border: 1px solid #E2E8F0;
      }
  
      .partner__name {
        color: $color-black;
        font-size: 20px;
        font-weight: 700;
        padding-left: 12px;
      }
    }
  }

  .exhibit-title__buttons {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
  }

  .exhibit-title__buttons button:first-child {
    margin-right: 8px;
  }

  .exhibitor-title__countries {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    

    .exhibitor-title__available-in {
      color: #4A4D57;
      font-size: 16px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      max-width: 377px;
      justify-content: center;

      .exhibitor-title__country {
        color: $color-black;
        font-size: 16px;
        font-weight: 600;

        .country {
          padding: 0 4px;
        }

        .country-divider {
          width: 20px;
          color: #4A4D57;
          display: inline-block;
          text-align: center;
          &:last-child {
            display: none;
          }
        }
      }
    }

    
  }

  @media (max-width: 767px) {
    .exhibit-title__wrap {
      max-width: 100%;
    }

    .exhibit-title__buttons {
      flex-direction: column-reverse;
      margin-right: 0px;
    }

    .exhibit-title__buttons button:last-child {
      margin-bottom: 8px;
    }

    .exhibitor-title__countries {
      justify-content: center;

      .exhibitor-title__available-in {
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  .exhibit-title__edit {

    @include tablet {
      // position: absolute;
      top: 32px;
      right: 30px;
      max-width: 229px;
    }
    
  }

  .exhibit-title__cancel {
    border-color: $blue400;
    color: $color-black;
    margin-bottom: 8px;

    @include tablet {
      position: absolute;
      top: 32px;
      right: 218px;
      border-color: $color-white;
      color: $color-white;
      margin-bottom: 0;
    }

    @include desktop-sm {
      right: 327px;
    }
  }

  .exhibit-title__desc {
    position: relative;
  }

  .exhibit-title__desc-error {
    position: absolute;
    top: 2px;
    right: 5px;
    font-size: 10px;
    color: $color-black;
  }

  .exhibit-title__edit-bg {
    width: auto;
    margin-bottom: 24px;
    cursor: pointer;

    > * {
      cursor: pointer;
    }

    // @include tablet {
      position: absolute;
      top: 32px;
      left: 80px;
      margin-bottom: 0;
    // }

    // @include desktop-sm {
    //   // left: 142px;
    // }
  }

  @media (max-width: 767px) {
    .exhibit-title__edit-bg {
      right: 20px;
      left: auto;
    }
  }

  .exhibit-title__tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;

    > li {
      &:not(:last-child) {
        margin-right: 6px;
        margin-bottom: 6px;
      }
    }
  }

  .exhibit-title__title {
    margin-top: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
    color: $color-black;
    // word-break: break-all;
    white-space: pre-line;

    @include tablet {
      font-size: 48px;
    }
  }

  .exhibit-title__logo {
    // position: absolute;
    // left: 10%;
    // bottom: -8%;
    // margin: 0 auto 16px;
    border-radius: 4px;

    .image-wrapper {
      display: inline-block;
      height: 80px;
      width: 80px;

      @include tablet {
        height: 160px;
        width: 160px;
      }
    }

    .image-wrapper__image {
      border-radius: 4px;
      border: 1px solid #E2E8F0;
      width: 100%;
      left: 0;
      background-color: white;
    }

    @include tablet {
      // margin: 0 auto 24px;
    }

    &.preview {
      .image-wrapper__image {
        box-shadow: 0px 6px 12px 0px rgba(74, 77, 87, 0.24);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  .exhibitor__site {
    padding-top: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $color-primary;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  .exhibitor-partner {
    display: flex;
    margin-top: 16px;
    align-items: center;
    
    .partner__background {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 60px;
      height: 60px;
      border-radius: 4px;
      border: 1px solid #E2E8F0;
    }

    .partner__name {
      color: $color-black;
      font-size: 20px;
      font-weight: 700;
      padding-left: 12px;
    }

    &.exhibitor-partner-link {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  > * {
    position: relative;
    padding-top: 24px;
    padding-bottom: 32px;

    @include tablet {
      padding-top: 60px;
      padding-bottom: 40px;
    }
  }

  p {
    max-width: 385px;
    margin-top: 16px;
    font-size: 16px;
    color: $color-black;
    word-wrap: break-word;
    white-space: normal;
  }

  .input-item {
    margin-bottom: 8px;

    &--textarea {
      margin-bottom: 2px;
    }
  }

  @media (max-width: 767px) {
    .exhibit-title__logo {
      left: 15px;
      bottom: -14%;
    }
    .container  {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  @media (max-width: 1200px) {
    .exhibit-title__logo {
      bottom: -11%;
    }
  }

  textarea.input {
    min-height: 107px;

    @include tablet {
      min-height: 86px;
    }
  }
}

.exhibit-title__back {
  display: block;
  margin: 12px;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  color: $color-black;

  @include tablet {
    position: absolute;
    top: 23px;
    margin: 0px;
  }
}

.exhibit-title__back-icon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  padding: 2px;
}

.contact {
  position: relative;

  &__body {
    margin-bottom: 24px;
    width: 100%;

    @include tablet {
      margin-bottom: 24px;
    }
  }

  &__text {
    white-space: pre-line;
    font-size: 18px;
    font-weight: 600;
    color: $grey500;
    text-align: justify;
  }

  &__email {
    font-weight: 600;
    font-size: 18px;
    color: $grey500;
    text-decoration: none;

    // &:hover,
    // &:focus,
    // &:active {
    //   color: $color-primary;
    // }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }

    // .contact__display_title {
    //   margin-bottom: 0;
    // }
  }

  &__qr {
    display: none;
    position: absolute;
    top: -175px;
    left: 0px;
    margin: 0 auto;
    width: 175px;
    box-shadow: 0px 3px 6px $box-shadow-view;
    height: 175px;
    background-size: contain;
    background-repeat: no-repeat;

    @include tablet {
      left: 0px;
    }
  }

  &__site {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $color-primary;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  &__socials {
    @include tablet {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__title {
    margin-bottom: 16px;
  }

  p {
    margin-top: 16px;
    font-weight: 600;
    font-size: 16px;
    color: #4a4d57;
  }

  textarea.input {
    max-width: 100% !important;
  }
}

ul.socials {
  display: flex;
  margin: 0 auto;
  text-align: center;
  margin-right: -12px;
  margin-left: -12px;

  li {
    position: relative;
    padding-right: 12px;
    padding-left: 12px;
  }

  &__icon {
    display: inline-block;
  }
}

.popup-window {
  position: absolute;
  left: 50%;
  bottom: 10%;
  top: unset;
  width: 60%;
  height: 450px;
  transform: translateX(-50%);
  background-color: $white;
  padding: 20px;
  z-index: 5;
  border: 2px solid $black;
  border-radius: 25px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  flex-grow: 1;
  @include for-phone-only {
    height: 350px;
    width: 80%;
  }

  &__close {
    cursor: pointer;
  }
  &__media {
    max-height: 400px;
    width: 90%;
    margin: 0px 30px 10px 30px;
    @include for-phone-only {
      max-height: 275px;
      margin: 0px 30px 0px 25px;
    }
  }
}

.hover-effect {
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    transform: scale(1.1);
  }
}

.table-top-section {
  width: 100%;
  padding: 0 15%;
  @include for-phone-only {
    display: none;
  }

  @include for-small-tablet {
    padding: 0 8%;
  }

  @include for-large-tablet {
    padding: 0 8%;
  }
}

.table-column {
  margin: 20px auto;
  width: 100%;

  &__title {
    width: 16%;
    position: relative;
    float: left;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 30px;

    color: #858795;

    @include for-phone-only {
      display: none;
    }
    &__line-full-width {
      margin: 20px auto 0px auto;
      border-top: 1px solid $black;
      @include for-phone-only {
        display: none;
      }

      @include for-small-tablet {
        margin-top: 40px;
      }

      @include for-large-tablet {
        margin-top: 40px;
      }
    }
  }
  &__text {
    width: 16%;
    position: relative;
    float: left;

    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;

    color: #000000;

    @include for-phone-only {
      text-align: center;
      width: 100%;
    }
  }
  &__logo {
    position: relative;
    float: left;
    width: 7%;
    &__logoSize {
      height: 30px;
      @include for-phone-only {
        height: 50px;
        margin: auto;
      }
    }
    @include for-phone-only {
      width: 100%;
    }
  }
  &__subscriptionType {
    position: relative;
    float: left;
    width: 16%;
    &__title {
      display: none;

      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 30px;

      color: #858795;
      @include for-phone-only {
        display: inline;
        float: left;
        text-align: center;
        width: 50%;
      }
    }
    &__text {
      //width: 12%;
      float: left;

      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;

      color: #000000;
      @include for-phone-only {
        width: 100%;
      }
    }

    &__img-browser {
      position: relative;
      float: left;
      height: 30px;
      display: inline;

      @include for-phone-only {
        display: none;
      }
    }
    &__img-mobile {
      display: none;

      @include for-phone-only {
        display: inline;
        height: 90px;
      }
    }

    @include for-phone-only {
      display: block;
      float: left;
      text-align: center;
      width: 50%;
    }
  }
  &__subscriptionEnd {
    &__title {
      width: 16%;
      position: relative;
      float: left;
      display: none;

      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 30px;

      color: #858795;

      @include for-phone-only {
        display: inline;
        float: left;
        text-align: center;
        width: 50%;
      }
    }
    &__text {
      width: 20%;
      position: relative;
      float: left;

      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;

      color: #000000;

      @include for-phone-only {
        float: left;
        text-align: center;
        width: 50%;
      }
    }
  }
  .blue-button {
    width: auto;
    height: 100%;
    border-radius: 20px;
    margin: 0px auto 0px 8px; //0 a 0 a
    padding: 5px 20px;
    background-color: $darkblue;
    color: $white;
    font-family: "montserratbold";
    font-size: 18px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.02);
    }
    &:focus {
      outline: 0;
    }
    &:disabled {
      opacity: 0.6;
      cursor: default;
      transform: none;
    }
    @include for-phone-only {
      width: 45%;
      font-size: 14px;
      &:hover {
        transform: unset;
      }
    }
    &--standard-width {
      width: 350px;
    }
  }
  &__button {
    margin: 0px auto 0px auto;
    @include for-phone-only {
      width: 100%;
      text-align: center;
      overflow: hidden;
    }
  }
  &__reminder {
    text-align: center;
    padding: 5px;
    font-family: "signikasemibold";
    max-height: 40px;

    margin-top: 18px;
    @include for-phone-only {
      font-size: 12px;
      padding: 5px 10px;
    }

    @include for-small-tablet {
      font-size: 15px;
    }
  }
}

.statistics {
  display: flex;
  justify-content: space-between;
  margin: 40px 0px 80px 0px;

  @include for-phone-only {
    flex-direction: column;
    margin-bottom: 0;
  }

  &__bar-chart {
    height: 200px;
    min-width: 400px;

    @include for-phone-only {
      min-width: 80vw;
    }
  }

  .recharts-text,
  .recharts-tooltip-wrapper {
    font-family: "signikaregular";
  }
}

.statistics-name {
  font-family: signikaregular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.statistics-stat {
  padding: 20px;
  border: 1px solid grey;
  text-align: center;
  font-family: "signikaregular";
  width: 40%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  @include for-phone-only {
    width: auto;
    height: 150px;
    margin-left: 0;
  }

  &:nth-of-type(1) {
    width: auto;
    margin-left: 0;
  }

  &__wrap {
    display: flex;
    justify-content: space-around;
    flex-grow: 1;

    @include for-phone-only {
      flex-direction: column;
      margin-top: 25px;
    }

    .statistics-stat {
      &:nth-of-type(1) {
        margin-left: auto;
        width: 40%;

        @include for-phone-only {
          margin: 20px 0;
          width: auto;
        }
      }
    }
  }

  &__data {
    font-size: 16px;
  }
  &__label {
    font-size: 12px;
  }
}

.button-download {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px;
  background-color: $color-white;
  border-radius: 6px;
  color: #4a4d57;
  text-decoration: none;
  cursor: pointer;

  + .button-download {
    margin-top: 12px;
  }
}

.button-download-icon {
  font-size: 20px;
  margin-left: auto;
  color: $color-primary;
}

.lifestyle {
  .swiper-button-next,
  .swiper-button-prev {
    height: 100%;

    @include tablet-large {
      display: none;
    }
  }

  .swiper-wrapper {
    width: auto !important;
    margin-right: -9px;
    margin-left: -9px;

    @media (min-width: 1024px) {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .swiper-slide {
    @media (min-width: 1024px) {
      width: auto !important;
      margin-top: 0 !important;
      margin-bottom: 16px;
      margin-right: 0 !important;
      padding-right: 7px;
      padding-left: 7px;
    }
  }

  li {
    .add-more {
      min-height: 300px;

      @include tablet {
        min-height: 300px;
      }

      @include tablet-large {
        width: 277px;
        min-height: 386px;
      }
    }

    .add-more__label {
      min-height: inherit;
    }

    @include tablet {
      .add-more {
        display: flex;
        margin: 0;

        .add-more__label {
          height: 100%;
        }
      }
    }
  }

  .lifestyle__image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    min-height: 300px;
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;

    @media (min-width: 1024px) {
      width: 277px;
      min-height: 386px;
    }
  }

  .lifestyle__video {
    width: 100%;
    height: 300px;
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;
    overflow: hidden;
    display: flex;

    @media (min-width: 1024px) {
      width: 277px;
      height: 386px;
    }
    video{
      width: 100%;
      height: 100%;
      object-fit: cover !important;
    }
  }

  .lifestyle-input-wrapper {
    display: flex;
    padding: 0 12px 12px 12px;

    .lifestyle-left-container {
      flex: 1 1;
      width: 0;

      .lifestyle-posted {
        color: #888C9D;
        font-size: 12px;
      }

      .input-item--textare, .input-item {
        margin-bottom: 4px;
      }
    }

    .lifestyle-right-container {
      width: fit-content;
      min-width: 46px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .lifestyle-like {
        // position: absolute;
        // top: 0;
        // right: 0;
        font-size: 26px;
        text-align: right;
        background: none;
        border: 0;
        padding: 0;

        .icon-like-fill {
          color: $favorite;
        }
      }

      .lifestyle-like-count {
        color: #888C9D;
        text-align: center;
        font-size: 10px;
      }

    }
  }
}

.exhibit-delete {
  position: absolute;
  top: 12px;
  left: auto;
  right: 12px;
  display: block;
  width: 24px;
  height: 24px;
  background-color: $color-white;
  border: none;
  border-radius: 5px;
  padding: 0;
  transition: $transition-duration;
  cursor: pointer;
  z-index: 1;

  &:hover {
    background-color: $grey50;
  }
}

.exhibit-delete-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  color: $color-black-00;
  z-index: 1;
}

.lifestyle__title {
  margin-bottom: 46px;
  text-align: center;
}

.exhibit-lifestyle {
  margin-right: -15px;

  @include desktop-sm {
    margin-right: 0;
  }

  .add-more {
    // display: block;

    @media (min-width: 1024px) {
      margin: 24px 0 0;
    }

    .add-more__label {
      height: 128px;
      background-color: rgba(0, 0, 0, 0);
    }

    // @include tablet {
    //   display: none;
    // }
  }

  &__video {
    position: relative;
    margin-top: 32px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    @include tablet {
      height: 337px;
      margin-top: 0;
    }

    video {
      width: 100%;
    }
  }

  &__video-play {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 44px;
    height: 44px;
    background: none;
    border: 0;
    border-radius: 50%;
    background-color: $color-white;
    color: $color-primary;
    cursor: pointer;
  }
}

.exhibit-history {
  display: flex;
  flex-direction: column;

  &__info {
    @include tablet-large {
      padding-right: 48px;
    }

    &.edit-mode {
      padding-right: 0;
    }
  }

  &__title {
    margin-bottom: 32px;
  }

  &__video {
    position: relative;
    margin-top: 32px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    @include tablet {
      height: 337px;
      margin-top: 0;
    }

    video {
      width: 100%;
    }
  }

  &__video-play {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 44px;
    height: 44px;
    background: none;
    border: 0;
    border-radius: 50%;
    background-color: $color-white;
    color: $color-primary;
    cursor: pointer;
  }

  p {
    white-space: pre-line;
    margin-bottom: 24px;
  }

  &__text {
    display: block;
    color: $grey500;
    font-size: 16px;
    // word-break: break-all;
    display: block;
  }

  .read-more-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding-inline: 0;

    .btn-text {
      color: $grey500;
      font-size: 16px;
      line-height: 26px;
      font-weight: 700;
    }

    .btn-icon {
      font-size: 24px;
      line-height: 26px;
      padding-left: 4px;
    }
  }

  textarea.input {
    min-height: 332px;
    font-size: 16px;
    line-height: 22px;

    @include tablet {
      min-height: 204px;
      font-size: 14px;
    }
  }

  .input-file {
    display: none;

    @include tablet {
      display: inline-block;
    }
  }

  .button {
    display: block;

    @include tablet {
      display: none;
    }
  }
}

.exhibit-downloads,
.contact {
  .input-file {
    display: block;

    @include tablet {
      display: inline-block;
    }
  }

  .input {
    width: 100%;

    @include tablet {
      width: auto;
    }
  }

  .input-item,
  .input-file {
    margin-bottom: 8px;

    &--textarea {
      margin-bottom: 2px;
    }
  }

  textarea.input {
    width: 100%;
    max-width: 690px;
    min-height: 190px;

    @include tablet {
      width: 100%;
    }
  }

  @include tablet {
    .input-item {
      .input {
        width: 100%;
        max-width: 317px;
      }

      textarea.input {
        max-width: 690px;
      }

      &.input-icon-left {
        margin-bottom: 12px;
      }
    }
  }
}

.exhibit-downloads {
  .h5 {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.03em;
    text-align: center;
    color: $color-black;
    margin: 0 0 24px;

    @include tablet {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02em;
      text-align: left;
      color: $grey500;
      margin: 0 0 16px;
    }
  }

  .image-wrapper {
    &__image {
      height: 200px;
      top: 16px;
      left: 8px;

      @include tablet-large {
        height: 330px;
      }
    }

    &.four-three:before {
      padding-top: 0;
    }

    &__title {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.03em;
      color: $color-black;
      margin: 216px 0 8px;

      @include tablet-large {
        font-size: 20px;
        line-height: 27px;
        margin: 354px 0 16px;
      }
    }

    &__text {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: $grey500;
      margin: 0;

      @include tablet-large {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

.exhibit-social {
  display: block;

  .input-item {
    .input {
      width: 100%;
      max-width: 100%;
    }
  }

  .wechat-input-wrapper {
    display: flex;
    gap: 11px;

    .input-item {
      flex-grow: 1;
      // .input {
      //   width: 45%;
      //   max-width: 45%;
      // }
    }

    .input-file {
      flex-grow: 1;
      min-width: 50%;
    }
  }

  @include tablet {
    max-width: 620px;
  }
}

.exhibit-videos {
  .h2 {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.03em;
    text-align: center;
    color: $color-black;
    margin: 0 0 24px;

    @include tablet {
      font-size: 32px;
      line-height: 37px;
      margin: 0 0 32px;
    }
  }

  .card-discover__title {
    @include tablet {
      font-weight: 600;
      text-align: center;
      text-transform: none;
    }
  }

  .card-discover__subtitle {
    display: none;
    text-align: center;
  }
}

.exhibit-title__logo-edit {
  display: inline-block;
  margin-left: 12px;
  padding: 0;
  background: none;
  border: 0;
  font-size: 21px;
  color: $color-black;
  cursor: pointer;

  input {
    display: none;
  }
}

.videos {
  display: block;
  width: 100%;
  margin: 0 auto;

  .input-file {
    margin-bottom: 8px;
  }

  .input-item {
    margin-bottom: 32px;

    @include tablet {
      margin-bottom: 8px;
    }
  }

  @include tablet {
    max-width: 290px;
  }
}

.videos-delete {
  display: none;
  width: 100%;
  max-width: 80px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: $blue400;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 8px auto 32px;
  padding: 0;
  transition: $transition-duration;
  cursor: pointer;

  &:hover {
    color: $blue500;
  }

  @include tablet {
    display: block;
  }
}

img.socials__icon.socials__icon-wechat:hover + .contact__qr {
  display: block;
}

.about-section {
  padding-top: 0px;
  padding-bottom: 40px;
  background-color: $color-white;
}

.navigate-section {
  padding-top: 40px;

  .filters__button.nav-title {
    color: $color-black;
    text-align: center;
    font-family: $font-family-secondary;
    font-size: 12px;

    @include tablet{
      font-size: 32px;
    }

    &.active {
      color: $color-black;
      font-weight: 400;

      &:before {
        content: "";
        position: absolute;
        right: 0;
        bottom: -6px;
        left: 0;
        width: 5px;
        height: 5px;
        margin-right: auto;
        margin-left: auto;
        border-radius: 50%;
        background-color: $color-black;
      }
    }
  }
}

.exhibitor-navigation-header {
  top: 74px; // header fix hight
  width: 100%;
  padding: 0 20px;
  background-color: $color-white;
  z-index: $zindex-subheader;
  border: 1px solid $grey150;
  box-shadow: 0px 3px 6px 0px rgba(197, 208, 223, 0.50);
  font-size: 14px;
  opacity: 0;

  .navigate-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 72px;
    max-height: 72px;
    overflow: hidden;
  }

  .navigate-header-back {
    flex: 1;

    @include tablet-large {
      min-width: 200px;
    }

    .exhibit-header__back {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 12px;
      font-weight: 700;
      text-decoration: none;
      color: $color-black;

      @include tablet-large {
        font-size: 20px;
        gap: 20px;
      }


      .exhibit-header__back-icon {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        margin-right: 8px;
        padding: 2px;

        @include for-phone-only {
          width: 12px;
          height: 12px;
        }
      }
    }
    
  }

  .navigate-header-filter {
    .filters {
      justify-content: center;
      list-style-type: none;
      padding: 0 5px 7px;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      gap: 5px;

      @include tablet-large {
        gap: 20px;
      }
    }

    .filters__button.nav-title {
      color: $color-black;
      text-align: center;
      font-size: 12px;
      font-family: $font-family-secondary;

      @include tablet-large {
        font-size: 20px;
      }
  
      &.active {
        color: $color-black;
        font-weight: 400;
  
        &:before {
          content: "";
          position: absolute;
          right: 0;
          bottom: -6px;
          left: 0;
          width: 5px;
          height: 5px;
          margin-right: auto;
          margin-left: auto;
          border-radius: 50%;
          background-color: $color-black;
        }
      }
    }
  }

  .exhibit-title__buttons {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    margin-left: 12px;
  }

  .exhibit-title__buttons button:first-child {
    margin-right: 8px;
  }

  .exhibit-title__actions {
    min-width: 10px;
    white-space: nowrap;
    font-size: 10px;

    > * {
      margin-left: 12px;
      padding: 10px 5px;
      @include tablet-large {
        font-size: 14px;
        padding: 10px 24px;
      }
    }
  }
  
}

.exhibitor-header-fixed {
  position: fixed;
  transition: all 0.2s ease-in-out;
  opacity: 0;

  @include tablet{
    opacity: 1;
  }
}

.product-section {
  padding-bottom: 80px;
  padding-top: 80px;
}

.catalog__title {
  margin-bottom: 30px;
  text-align: center;
}

.catalog__subtitle {
  color: #4A4D57;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
}

.contact__title, .social-media__title {
  color: #4A4D57;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
}