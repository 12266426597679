@import "../../../styles/base/fonts.scss";
@import "../../../styles/mixins/breakpoint";
@import "../../../styles/variables.scss";

.offer-slider-container {
  // background-color: $color-white;
  padding: 0 20px;
  // box-shadow: 0px 20px 30px 0px rgba(51, 122, 228, 0.15), 0px 10px 15px 0px rgba(51, 122, 228, 0.10);
  position: relative;

  .swiper-container {
    z-index: 0;
  }
}

.offer-slider {
  // background-color: #F1F3F7;
  padding: 20px 0;

  @include desktop-sm {
    margin-right: 0;
  }

  .swiper-slide {
    margin-right: auto !important;
    margin-left: auto !important;
    padding-right: 8px;
    padding-left: 8px;
  }
}

.card-discover {
  cursor: pointer;
  text-decoration: none;
  transition: ease-in .25s;
  &:hover{
    transform: translate(0, -8px);
  }

  &--video {
    .card-discover__image {
      height: 300px;
      border-radius: 6px;
      cursor: pointer;

      @include tablet {
        height: 370px;
      }

      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        pointer-events: none;
      }
    }
  }

  &__image {
    height: 294px;
    margin-bottom: 12px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    @include tablet {
      width: 100%;
    }
  }

  &__title {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    text-transform: uppercase;
    text-align: center;
    color: $grey500;
  }

  &__subtitle {
    font-weight: 40;
    color: $grey500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  &__play {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    background-color: $color-white;
    border-radius: 50%;
    z-index: 1;
    color: $primary-color;

    svg {
      position: absolute;
      top: 0;
      left: 4px;
      right: 0;
      bottom: 0;
      margin: auto;
      display: block;
      width: 18px;
      height: 18px;
      fill: $blue400;
      color: $blue400;
    }

    @include tablet {
      width: 40px;
      height: 40px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.offers-swiper-button-next {
  right: -6px;
  // background: $color-white;
  background-color: transparent;
  border: 0;
  height: 100%;
  width: 30px;
  top: 22px;
  padding: 0;

  position: absolute;
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: var(--swiper-theme-color);
  color: var(--swiper-navigation-color, var(--swiper-theme-color));

  &.swiper-button-disabled{
    display: flex;
    opacity: 1;
    &:before{
      opacity: 0;
    }
  }

  .card-slider-video & {
    top: 0 !important;
    margin-top: 0;

    @include tablet {
      height: 370px;
    }
  }

  &:before {
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @extend .icon-chevron-right-light;
    font-size: 20px;
    color: $color-black;
    height: 330px;
    display: flex;
    align-items: center;
  }
}

.offers-swiper-button-prev {
  left: -6px;
  // background: $color-white;
  background-color: transparent;
  border: 0;
  padding: 0;
  width: 30px;
  height: 100%;
  top: 22px;

  position: absolute;
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &.swiper-button-disabled{
    display: flex;
    opacity: 1;
    &:before{
      opacity: 0;
    }
  }

  .card-slider-video & {
    top: 0 !important;
    margin-top: 0;

    @include tablet {
      height: 370px;
    }
  }

  &:before {
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @extend .icon-chevron-right-light;
    font-size: 20px;
    color: $color-black;
    transform: rotate(180deg);
    height: 330px;
    display: flex;
    align-items: center;
    z-index: 1;
    @include tablet{
      height: 370px;
    }
  }
}

.swiper-button-next {
  right: 0;
  background: none;
  border: 0;
  padding: 22px;
  height: 300px;
  top: 22px;
  background: linear-gradient(
    89.66deg,
    rgba(241, 243, 246, 0.2) -6.33%,
    #f1f3f6 84.34%
  );

  .offer-slider-video & {
    top: 0 !important;
    margin-top: 0;

    @include tablet {
      height: 370px;
    }
  }

  @include tablet {
    right: 0;
  }

  &:after {
    display: none;
  }

  &:before {
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @extend .icon-chevron-right-light;
    font-size: 20px;
    color: $color-black;
  }
}

.swiper-button-prev {
  left: 0;
  background: none;
  border: 0;
  padding: 0;
  height: 300px;
  top: 22px;

  .offer-slider-video & {
    top: 0 !important;
    margin-top: 0;

    @include tablet {
      height: 370px;
    }
  }

  @include tablet {
    left: 10px;
  }

  &:after {
    display: none;
  }

  &:before {
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @extend .icon-chevron-right-light;
    font-size: 20px;
    color: $color-black;
    transform: rotate(180deg);
  }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  display: none !important;
}

.discover-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 294px;
  padding: 32px;
  border: 2px solid $primary-color;
  color: $primary-color;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  text-decoration: none;
  transition-property: border-color, color;
  transition-duration: $transition-duration;
  cursor: pointer;

  &:hover {
    border-color: $primary-color-hover;
    color: $primary-color-hover;

    [class^="icon-"] {
      border-color: $primary-color-hover;
    }
  }

  &:active {
    border-color: $primary-color;
    color: $primary-color;

    [class^="icon-"] {
      border-color: $primary-color;
    }
  }

  > span {
    text-align: center;
    font-size: 14px;
    line-height: 1.4;
  }

  [class^="icon-"] {
    position: absolute;
    right: 20px;
    bottom: -25px;
    font-size: 90px;
  }
}

.offer-slider.offer-slider-centered {
  .swiper-slide {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .swiper-wrapper {
    justify-content: center;
  }
}
