.terms-conditions {
  display: block;
  width: 100%;
  padding-top: 60px;
  text-align: justify;

  &__header {
    font-family: $font-family-secondary;
    font-size: 30px;
    text-align: center;
    color: $color-black;
    margin-bottom: 26px;

    @include for-phone-only {
      font-size: 20px;
    }
  }
  &__sub-header {
    font-size: 18px;
    margin-bottom: 20px;

    @include for-phone-only {
      font-size: 18px;
    }
  }

  &__paragraph {
    font-size: 14px;
    line-height: 1.45;
    letter-spacing: -0.3px;
    text-align: left;
    color: $grey500;
    margin: 0 0 16px;
    &.no-margin {
      margin: 0;
    }
    @include for-phone-only {
      font-size: 12px;
    }
  }

  &__bullet-point {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.45;
    letter-spacing: 0;
    text-align: left;
    color: $grey500;
    margin: 0 0 16px;
    @include for-phone-only {
      font-size: 12px;
    }
  }

  ul {
    margin: 0 0 16px;
    padding: 0;
    list-style-type: none;

    li {
      display: block;
      font-size: 14px;
      line-height: 1.45;
      letter-spacing: -0.3px;
      text-align: left;
      color: $grey500;
      @include for-phone-only {
        font-size: 12px;
      }
    }
  }

  a {
    text-decoration: none;
    color: $grey500;
  }

  @include tablet-large {
    padding-left: 118px;
    padding-right: 125px;
  }
}

.indent {
  text-indent: 4%;
}

.double-indent {
  text-indent: 8%;
}

// footer
.terms-footer {
  margin: 0 auto;
  padding: 0 10px;

  @include tablet {
    padding: 0 50px;
  }

  @include tablet-large {
    padding: 0 240px;
  }

  @include desktop {
    max-width: 1440px;
    padding: 0 320px;
  }

  .small-medium-container {
    display: block;
  }

  .terms-footer-divider {
    display: block;
    margin-bottom: 30px;
    padding-bottom: 18px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: auto;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      display: block;
      width: 68%;
      height: 1px;
      background-color: $grey-line;
      z-index: 1;
    }

    .header-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;

      .line {
        border-bottom: 1px solid #b6b6b6;
        flex-grow: 0.4;
        height: 1px;

        @include desktop {
          flex-grow: 0.9;
        }
      }

      .text {
        font-weight: 600;
        letter-spacing: -0.3px;
        color: #b6b6b6;
        margin: 0 10px;
      }
    }

    ul {
      display: flex;
      justify-content: space-around;
      list-style: none;
      flex-wrap: wrap;
      text-align: center;
      margin: 0;
      padding: 0 30px;

      @include small-phone {
        padding: 0;
      }

      @include desktop {
        padding: 0;
      }

      li {
        display: inline-block;
        width: 100px;
        height: 50px;
        margin: 0 10px 16px;
        cursor: pointer !important;
        a {
          margin: 0px auto;
        }
        span {
          display: block;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          filter: grayscale(1);
          margin: auto;

          @include desktop {
            min-height: auto;
            height: 50px;
          }

          &:hover {
            filter: none;
            transition: filter 0.3s;
          }
        }

        @include tablet-large {
          margin: 0 0 16px;
        }

        @include for-phone-only {
          flex-basis: calc(50% - 20px);
          max-width: calc(50% - 20px);
        }
      }
    }

    &.large {
      ul {
        &:nth-child(2) {
          li {
            &:nth-of-type(1) span {
              background-image: url("/assets/images/park-hyatt.png");
            }

            &:nth-of-type(2) {
              span {
                background-image: url("/assets/images/miraval.png");
              }
            }

            &:nth-of-type(3) span {
              background-image: url("/assets/images/hyatt-grand.png");
            }

            &:nth-of-type(4) {
              span {
                background-image: url("/assets/images/hyatt-regency-logo.png");
              }
            }

            &:nth-of-type(5) {
              span {
                background-image: url("/assets/images/hyatt.png");
              }
            }
          }
        }

        &:nth-child(3) {
          li {
            &:nth-of-type(1) {
              span {
                background-image: url("/assets/images/hyatt-zilara.png");
              }
            }

            &:nth-of-type(2) {
              span {
                background-image: url("/assets/images/hyatt-place.png");
              }
            }

            &:nth-of-type(3) {
              span {
                background-image: url("/assets/images/hyatt-house.png");
              }
            }

            &:nth-of-type(4) {
              span {
                background-image: url("/assets/images/ur-cove.png");
              }
            }

            &:nth-of-type(5) span {
              background-image: url("/assets/images/hyatt-residence-club.png");
            }
          }
        }
      }
      li {
        flex-basis: 100px;

        @include desktop {
          flex-basis: 100px;
        }
      }
    }

    &.medium {
      li {
        &:nth-of-type(1) span {
          background-image: url("/assets/images/andaz.png");
        }

        &:nth-of-type(2) {
          span {
            background-image: url("/assets/images/alila.png");
          }
        }

        &:nth-of-type(3) {
          span {
            background-image: url("/assets/images/thompson-hyatt.png");
          }
        }

        &:nth-of-type(4) span {
          background-image: url("/assets/images/hyatt-centric-vector-logo.png");
        }

        &:nth-of-type(5) span {
          background-image: url("/assets/images/exhale.png");
        }
      }
    }

    &.small {
      &:after {
        content: none;
      }

      li {
        &:nth-of-type(1) span {
          background-image: url("/assets/images/ub.png");
        }

        &:nth-of-type(2) span {
          background-image: url("/assets/images/destination.png");
        }

        &:nth-of-type(3) span {
          background-image: url("/assets/images/joie-de-vivre.png");
        }
      }
    }
  }
}
