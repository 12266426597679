.auth-header {
  min-height: 74px;
  padding-top: 12px;
  padding-bottom: 11px;
  border-bottom: 1px solid #d8dae7;

  &__hde {
    margin: 0 auto;
  }
}

.signin-wrap {
  display: flex;
  height: 100%;

  @include tablet {
    height: 100vh;
  }

  p {
    font-size: 12px;
    letter-spacing: -0.2px;
    margin-bottom: 12px;

    @include tablet {
      margin-bottom: 8px;
    }
  }
}

.signin-title {
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 24px;
  letter-spacing: -0.2px;
}

.signin-form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  padding-right: 15px;
  padding-bottom: 32px;
  padding-left: 15px;
  margin: auto;
  background-repeat: no-repeat;
  background-size: cover;

  @include desktop-sm {
    width: 80%;
    padding-right: 110px;
    padding-bottom: 40px;
    padding-left: 110px;
  }

  @include desktop {
    width: 64%;
  }

  &__forgot-password {
    margin-top: -12px;
    margin-bottom: 24px;

    .link {
      font-size: 12px;
      line-height: 16px;
    }

    @include tablet {
      margin-top: 0;

      .link {
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }

  &__text {
    font-size: 12px;
    line-height: 16px;

    .link {
      font-size: 12px;
      line-height: 16px;
    }

    @include tablet {
      font-size: 14px;
      line-height: 1.5;

      .link {
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }

  .button-primary {
    margin-bottom: 14px;
  }

  h3 {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $grey500;
  }

  p {
    font-size: 12px;

    @include tablet {
      font-size: 14px;
    }
  }

  .blue-button {
    margin-top: 20px;
    min-width: 250px;
    &:last-of-type {
      margin-bottom: 40px;
    }
  }
  .blue-button-link {
    width: 250px;
  }
}

.signin-image {
  display: none;

  @include tablet-large {
    display: block;
    position: relative;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
}

.signin-image-title {
  position: absolute;
  right: 0;
  bottom: 33px;
  left: 0;
  margin-bottom: 0;
  font-size: 47px;
  letter-spacing: -1.3px;
  text-align: center;
  color: $color-white;
}

.signin-logo-bottom {
  margin-top: 64px;
  margin-right: auto;
  margin-left: auto;

  @include desktop-md {
    margin-top: 120px;
  }
}

.signin-logo-top {
  width: 120px;
  height: 120px;
  margin-right: auto;
  margin-bottom: 70px;
  margin-left: auto;

  @include tablet {
    width: 150px;
    height: 150px;
    margin-bottom: 40px;
  }
}

.reset-form {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 40px;
  &__title {
    margin: 20px auto;
    font-size: 20px;
    text-transform: uppercase;
  }
  &__back-button {
    font-size: 16px;
    width: 100px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .blue-button {
    margin-top: 20px;
    min-width: 250px;
  }
}

.switch-exhibitor {
  color: $color-primary;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 16px;
  cursor: pointer;
}

.exhibitor-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  
  .back-to-icon {
    font-size: 12px;
    font-weight: 900;
    padding-right: 12px;
    margin-bottom: 14px;
    cursor: pointer;
  }
}
