@import "../../../styles/variables";
@import "../../../styles/mixins/breakpoint";

.add-more {
  position: relative;
  height: 100%;
  margin-top: 24px;

  &__input {
    position: absolute;
    overflow: hidden;
    margin: -1px;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
  }

  &__label {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid $primary-color;
    color: $primary-color;
    font-size: 16px;
    line-height: 22px;
    background-color: $input-background-color;
    font-weight: 600;
    transition-property: border-color, color;
    transition-duration: $transition-duration;

    &:hover {
      border-color: $primary-color-hover;
      color: $primary-color-hover;

      [class^="icon-"] {
        border-color: $primary-color-hover;
      }
    }

    &:active {
      border-color: $primary-color;
      color: $primary-color;

      [class^="icon-"] {
        border-color: $primary-color;
      }
    }

    [disabled] + & {
      border-color: $primary-disabled;
      color: $primary-disabled;
      pointer-events: none;

      [class^="icon-"] {
        border-color: $primary-disabled;
      }
    }
  }

  [class^="icon-"] {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-bottom: 12px;
    width: 41px;
    height: 41px;
    border-radius: 50%;
    border: 3px solid $primary-color;
    transition-property: border-color;
    transition-duration: $transition-duration;
    font-size: 20px;
    line-height: 1;
    font-weight: 900;
  }

  @include tablet-large {
    margin-top: 0;

    &__label {
      max-width: 290px;
    }
  }
}

.add-more-desktop {
  display: none;

  @include tablet-large {
    display: block;
  }
}

.add-more-mobile {
  @include tablet-large {
    display: none;
  }
}
