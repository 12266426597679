// Color
$color-white: #fff;
$color-black: #121318;
$color-black-00: #000000;
$color-black-secondary: #0e0f1b;
$blue100: #acb9cb;
$blue400: #337ae4; // hyatt blue
$blue500: #134ea6;
$blue900: #151f6d;
$blue-tag: #c1dbff;
$blue-cross: #d9dae0;
$blue-filter: #5e6775;
$favorite: #ffbf00;
$like: #E43373;

$grey50: #d5dae2;
$grey100: #e2e8f0;
$grey150: #dde2e9;
$grey200: #737982;
$grey500: #4a4d57;
$grey-border: #d1d9de;
$grey-delete: #caccd4;
$grey-input: #d5e3eb;
$grey-subtitle: #b2b6c2;
$grey-line: #eaecf4;

$primary-color: $blue400;
$primary-color-hover: $blue500;

// RGBA Color opacity
$blue900_008: rgba(21, 31, 109, 0.08);
$grey600_008: rgba(255, 255, 255, 0.08);
$grey100_032: rgba(19, 37, 65, 0.32);

// Fonts
$font-size: 16px;
$font-family-primary: "Open Sans", Arial, sans-serif;
$font-family-secondary: "Yeseva One", Arial, sans-serif;
$white-text-color: #fff;
$text-color: $color-black;
$opacity-text-color: $grey500;

// Typography
$title-font-family: $font-family-primary;

// Grid
$container-width-mobile-lg: 414px;
$container-width-mobile-xxl: 460px;
$container-width-tablet: 768px;
$container-width-tablet-large: 992px;
$container-width-desktop-small: 1200px;
$container-width-desktop: 1440px;
$container-width-desktop-md: 1600px;
$container-width-desktop-lg: 1920px;
$container-padding-horizontal-mobile: 15px;

// States
$color-success: rgba(0, 255, 71, 0.2);
$color-danger: #ee2e24;
$color-error: rgba(189, 0, 0, 0.2);
$color-info: rgba(0, 168, 255, 0.2);

// Decorative global styles
$border-color: #e5e5e5;
$border-radius: 10px;
$box-shadow: rgba(19, 37, 65, 0.32);
$icon-color: #888c9d;
$box-shadow-card: rgba(74, 77, 87, 0.24);
$box-shadow-view: rgba(197, 208, 223, 0.5);

// Background
$bkcg-transparent: transparent;
$bkcg-white: #fff;
$background-color-grey: #f2f3f7;

// Button
$button-font-family: $font-family-primary;
$button-border-radius: 6px;
$primary-bckg: $primary-color;
$primary-border: $primary-color;
$primary-active-bckg: $blue500;
$primary-disabled: $blue100;
$button-box-shadow: $grey100_032;
$secondary-border-color: #fff;
$secondary-disabled: $grey200;
$secondary-light-hover: $blue500;
$secondary-hover: $grey600_008;
$primary-dark: $blue900;
$primary-dark-disabled: $blue900_008;

// Inputs
$input-font-size: 14px;
$input-padding-horizontal: 12px;
$input-padding-vertical: 12px;
$input-lg-padding-horizontal: 24px;
$input-lg-padding-vertical: 20px;
$input-border-color: #d9d9d9;
$input-border-color-hover: #89b0eb;
$input-border-color-focus: $primary-color;
$input-border-radius: 6px;
$input-background-color: $color-white;
$input-color: #121318;
$input-error-message-color: $color-danger;
$input-error-border-color: $color-danger;
$input-disabled-background-color: #f8f8f8;
$input-disabled-border-color: $border-color;
$input-icon-width: 44px;
// $input-file-icon-background-color: #eaeaea;
// $input-file-icon-color: $color-dark;

$label-color: #121318;

$placeholder-color: #888c9d;

$tag-color: $grey150;

// Transitions
$transition-duration: 0.25s;

// z-index
$base-zindex: 0;
$above-zindex: 2;

$zindex-header: $above-zindex + 1;
$zindex-subheader: $above-zindex;
$zindex-mobile-menu: $above-zindex + $zindex-header;
