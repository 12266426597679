@import "../mixins/breakpoint";

.section-account {
  height: 100%;
  padding-top: 24px;
  padding-bottom: 30px;

  @include tablet {
    padding-top: 60px;
    padding-bottom: 120px;
  }
}

.account-links {
  padding-bottom: 32px;

  > * {
    margin-right: 16px;
    margin-left: 16px;
    line-height: normal;

    @include tablet {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}

.information {
  padding-top: 75px;
}

ul.information-brand-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;

  > li {
    width: 100%;
    margin-bottom: 24px;

    @include tablet {
      width: 50%;
    }

    @include tablet-large {
      width: 25%;
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  .brandCard {
    max-width: calc(100% - 24px);
    margin-right: auto;
    margin-left: auto;

    @include tablet-large {
      max-width: 219px;
      margin: 0;
    }
  }
}

.information-illustration {
  margin: 15px auto -10px auto;
  display: flex;

  text-align: center;
  width: 100%;
}

.information-logo {
  margin: 15px auto -10px auto;
  display: flex;

  text-align: center;
}

.information-display {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 32px;

  &__name {
    font-size: 24px;
    line-height: 1;
    letter-spacing: -0.02em;
    font-weight: bold;
    overflow-wrap: anywhere;
    word-break: break-all;
  }
  &__email {
    font-size: 18px;
    text-decoration: underline;
    overflow-wrap: anywhere;
    word-break: break-all;
  }

  @include tablet-large {
    padding-top: 50px;
  }
}

.information-controls {
  display: flex;
  justify-content: center;
  .blue-button {
    margin: 20px 5px;
  }
}

.information-section {
  padding-top: 24px;
  padding-bottom: 24px;

  @include tablet {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.information-settings-save {
  margin-top: 16px;
  margin-bottom: 12px;
}

.information-settings-save,
.information-settings-cancel {
  @include tablet {
    margin-top: 40px;
    margin-bottom: 0;
  }
}

.information-tabs {
  justify-content: center;
  padding-bottom: 32px;
}

.information-title {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.02em;

  @include tablet-large {
    font-size: 24px;
  }

  &--brand {
    margin: 0 0 16px;

    @include tablet-large {
      margin: 0 0 50px;
    }
  }

  &--statistics {
    margin: 0 0 16px;

    @include tablet-large {
      font-size: 20px;
      line-height: 1.2;
      margin: 0 0 24px;
    }
  }
}

.information-subtitle {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: $color-black;
  margin: 0 0 12px;

  @include tablet-large {
    font-size: 16px;
    line-height: 24px;
  }
}

ul.stats-cards {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
  cursor: default;

  > li {
    width: 100%;
    margin-bottom: 24px;
    padding-right: 8px;
    padding-left: 8px;

    @include desktop {
      width: 45.5%;
    }
  }

  ul {
    margin-left: 10px;
    width: 98%;
    cursor: default;

    @include tablet {
      width: 175px;
    }
  }

  .pie-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.edit-profile {
  width: 400px;
  margin: 20px auto;
  text-align: center;
  &__back-button {
    margin: 0px auto;
    font-family: "montserratbold";
    font-size: 16px;
    cursor: pointer;
    color: $darkblue;
    width: 70px;
  }
  @include for-phone-only {
    width: 100%;
  }
}

.edit-profile-controls {
  text-align: center;
  margin-top: -20px;
  @include for-phone-only {
    margin-top: 10px;
  }
}

.statistics-row {
  display: flex;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  margin: 0 -8px 20px;
  padding: 6px 0;

  @include desktop {
    overflow-x: hidden;
    white-space: normal;
  }
}

.statistics-row-item {
  padding-right: 8px;
  padding-left: 8px;
}

.statistics-row-divider {
  max-width: 1159px;
  margin-top: 24px;
  margin-bottom: 16px;
  border-bottom: 1px solid $grey150;
}

//.statistics {
//	display: flex;
//	justify-content: space-between;
//	margin: 40px 0px 80px 0px;
//
//	@include for-phone-only {
//		flex-direction: column;
//		margin-bottom: 0;
//	}
//
//	&__bar-chart {
//		height: 200px;
//		min-width: 400px;
//
//		@include for-phone-only {
//			min-width: 80vw;
//		}
//	}
//
//	.recharts-text, .recharts-tooltip-wrapper {
//		font-family: 'signikaregular';
//	}
//}
//
//.statistics-stat {
//	padding: 20px;
//	border: 1px solid grey;
//	text-align: center;
//	font-family: 'signikaregular';
//	width: 40%;
//	height: 70%;
//	display: flex;
//    flex-direction: column;
//    align-items: center;
//    justify-content: center;
//	margin-left: auto;
//
//	@include for-phone-only {
//		width: auto;
//		height: 150px;
//		margin-left: 0;
//	}
//
//	&:nth-of-type(1) {
//		width: auto;
//		margin-left: 0;
//	}
//
//	&__wrap {
//		display: flex;
//		justify-content: space-around;
//		flex-grow: 1;
//
//		@include for-phone-only {
//			flex-direction: column;
//			margin-top: 25px;
//		}
//
//		.statistics-stat {
//			&:nth-of-type(1) {
//				margin-left: auto;
//				width: 40%;
//
//				@include for-phone-only {
//					margin: 20px 0;
//					width: auto;
//				}
//			}
//		}
//	}
//
//
//	&__data {
//		font-size: 16px;
//	}
//	&__label {
//		font-size: 12px;
//	}
//}
