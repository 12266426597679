.input-item {
  position: relative;
}

.select-icon {
  position: absolute;
  bottom: 12px;
  right: 12px;
  font-size: 20px;
  padding: 0;
  pointer-events: none;
}
