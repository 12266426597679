@import "../styles/mixins/breakpoint";
@import "../styles/variables";

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 576px) {
    max-width: $container-width-desktop;
  }

  @include tablet {
    max-width: $container-width-desktop;
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (min-width: 992px) {
    max-width: $container-width-desktop;
  }

  @include desktop {
    max-width: $container-width-desktop;
  }
}

.row > * {
  padding-right: 5px;
  padding-left: 5px;

  @include tablet {
    padding-right: 10px;
    padding-left: 10px;
  }
}
