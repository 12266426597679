.edit-booth-form {
  margin-top: 40px;
  text-align: center;
  .text-input {
    width: 350px;
    @include for-phone-only {
      width: 200px;
    }
  }
  .blue-button {
    margin: 20px auto;
    width: 300px;
  }
  .help-text {
    font-size: 12px;
    margin-top: -10px;
  }
  .line-centered {
    width: 38%;
  }
  .small-line-centered {
    width: 350px;
    margin: 10px auto 10px auto;
    text-align: center;
    border-top: 1px solid #b5bcc6;
  }
  &__download {
    font-size: 12px;
    margin-top: -10px;
    color: $darkblue;
    a {
      color: $darkblue;
    }
  }
}

.red {
  color: red !important;
}

.wechat-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  top: -2px;
  margin: 0px auto;
  img {
    position: absolute;
    left: 3px;
  }
  .help-text {
    position: relative;
    left: 20px;
  }
  .blue-button {
    height: 40px;
    margin-left: 51px;
    width: 251px;
  }
}

.edit-booth-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: -20px;
  font-size: 18px;
  color: $darkblue;

  @include for-phone-only {
    text-align: center;
  }

  &__title {
    font-size: 28px;
    text-transform: uppercase;
    color: $black;
    cursor: default;

    @include for-phone-only {
      font-size: 1.2em;
    }
  }
  &__left,
  &__right {
    cursor: pointer;
    @include for-phone-only {
      font-size: 0.8em;
    }
  }
}

.country-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 400px;
  margin: 20px auto;

  @include for-phone-only {
    width: 370px;
  }

  @include small-phone {
    width: 300px;
  }

  &__item {
    width: 33%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "signikaregular";
    font-size: 14px;

    @include for-phone-only {
      font-size: 10px;
    }

    @include small-phone {
      font-size: 10px;
    }
    .Checkbox {
      top: 0px !important;
      @include small-phone {
        font-size: 10px;
      }
    }
  }

  &__label {
    @include for-phone-only {
      font-size: 10px;
      width: 20px;
    }
  }
}

.text-area-wrapper {
  display: flex;
  flex-direction: column;

  .help-text {
    margin-top: 3px;
  }
}

.thumbnail-button {
  margin: 5px !important;
  width: 260px !important;
  font-size: 0.8em;
}
