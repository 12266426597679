@import "../../../styles/base/fonts.scss";
@import "../../../styles/mixins/breakpoint";
@import "../../../styles/variables.scss";

.no-modal-slide {
 .swiper-button-next {
    display: none;
 }
 .swiper-button-prev {
    display: none;
  }
}

.newsfeed-card-slider {
  margin-right: -15px;
  padding-left: 24px;
  padding-right: 24px;

  @include desktop-sm {
    margin-right: 0;
  }

  .swiper-slide {
    padding-right: 8px;
    padding-left: 8px;
  }

  .newsfeed-card-discover {
    text-decoration: none;

    &--video {
      .newsfeed-card-discover__image {
        height: 300px;
        cursor: pointer;

        @include tablet {
          height: 370px;
        }

        video {
          height: 100%;
          width: 100%;
          object-fit: cover;
          pointer-events: none;
        }
      }
    }

    &__image {
      height: 294px;
      margin-bottom: 12px;
      border-radius: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;

      @include tablet {
        width: 100%;
      }
    }

    &__title {
      color: $grey500;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.6px;
      margin-bottom: 4px;
      text-transform: uppercase;
      text-align: left;
      min-height: 30px;
    }

    &__subtitle {
      color: $grey500;
      font-size: 12px;
      min-height: 40px;
      letter-spacing: -0.24px;
      text-align: left;
    }

    &__play {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 50%;
      z-index: 1;
      color: $color-white;

      svg {
        position: absolute;
        top: 0;
        left: 4px;
        right: 0;
        bottom: 0;
        margin: auto;
        display: block;
        width: 18px;
        height: 18px;
        fill: $color-white;
        color: $color-white;
      }

      @include tablet {
        width: 100px;
        height: 100px;

        &.icon-play {
          font-size: 40px;
        }

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    &__full-screen {
      position: absolute;
      right: 16px;
      bottom: 23px;
      margin: auto;
      display: flex;
      width: 26px;
      height: 26px;
      z-index: 1;
      color: $color-white;

      svg {
        position: absolute;
        top: 0;
        left: 4px;
        right: 0;
        bottom: 0;
        margin: auto;
        display: block;
        width: 18px;
        height: 18px;
        fill: $color-white;
        color: $color-white;
      }

      @include tablet {
        width: 26px;
        height: 26x;

        &.icon-full-screen {
          font-size: 26px;
        }

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    .newsfeed-display-wrapper {
      display: flex;
      padding: 0 12px 12px 12px;

      .newsfeed-left-container {
        flex: 1 1;

        .newsfeed-posted {
          color: #888C9D;
          font-size: 12px;
        }

        .input-item--textare, .input-item {
          margin-bottom: 4px;
        }
      }

      .newsfeed-right-container {
        width: fit-content;
        min-width: 46px;
        margin-left: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .newsfeed-like {
          font-size: 26px;
          text-align: right;
          background: none;
          border: 0;
          padding: 0;

          .icon-like-fill {
            color: $favorite;
          }
        }

        .newsfeed-like-count {
          color: #888C9D;
          text-align: center;
          font-size: 10px;
        }

      }
    }
  }

  .swiper-button-next {
    right: -4px;
    background: $color-white;
    border: 0;
    height: 100%;
    top: 22px;
    padding: 0;

    .card-slider-video & {
      top: 0 !important;
      margin-top: 0;

      @include tablet {
        height: 370px;
      }
    }

    @include tablet {
      right: 0;
    }

    &:after {
      display: none;
    }

    &:before {
      font-family: "icomoon" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      @extend .icon-chevron-right-light;
      font-size: 20px;
      color: $color-black;
    }
  }

  .swiper-button-prev {
    left: -4px;
    background: $color-white;
    border: 0;
    padding: 0;
    height: 100%;
    top: 22px;

    .card-slider-video & {
      top: 0 !important;
      margin-top: 0;

      @include tablet {
        height: 370px;
      }
    }

    &:after {
      display: none;
    }

    &:before {
      font-family: "icomoon" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      @extend .icon-chevron-right-light;
      font-size: 20px;
      color: $color-black;
      transform: rotate(180deg);
    }
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    display: none !important;
  }

  
}



.newsfeed-swiper-box {
  position: relative;

  .newsfeed-card-slider.card-slider-centered {

    .swiper-wrapper {
      justify-content: center;
    }

    .modal-body {
      .swiper-wrapper {
        justify-content: flex-start;
        z-index: 1000;
      }
    }
  }

  .swiper-pagination-custom {
    bottom: -40px;
    display: flex;
    gap: 6px;
    justify-content: center;
    z-index: 0;
    
    .pagination-progress {
      width: 20px;
      height: 4px;
      // background: $grey-line;
      background: $placeholder-color;

      &.active {
        width: 40px;
        background: $blue900;
      }
    }
  }
}
