@import "../styles/variables.scss";

.slider-component {
  margin-top: 40px;
  margin-bottom: 40px;

  &__caption {
    padding-top: 0px;
    margin-top: -5px;
    text-align: center;
  }

  @include for-phone-only {
    margin-top: 20px;
    margin-bottom: 0px;

    &__caption {
      font-size: 12px;
      margin-bottom: -10px;
    }
  }

  &.centered-section {
    @include for-small-tablet {
      padding: 0;
      max-width: 80vw;
      margin: 0 auto;
    }
  }
}

.neg-top-margin {
  margin-top: -25px;
}

.swiper-wrapper {
  &.disable {
    transform: translate3d(0px, 0, 0) !important;
  }
}

.swiper-pagination {
  display: block;
  @include for-phone-only {
    bottom: 0px !important;
  }

  &.hide {
    display: none;
  }
}

.swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;

  @include for-phone-only {
    width: 12px !important;
    height: 12px !important;
  }
}

.swiper-pagination-bullet-active {
  background-color: white !important;
  border: 0.5px solid black;
}

.slide-component-slide {
  position: relative;

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__icon {
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__name {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);
    color: $white;
    text-transform: uppercase;
    font-family: "montserratbold";
    font-size: 22px;
  }

  &__blur {
    position: absolute;
    background-image: url(/assets/images/video-overlay.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 102%;
    top: 0px;
    left: -1px;
    right: -1px;
    bottom: 0px;
    z-index: 100000000;
    pointer-events: none;
  }

  &.blur-active {
    .image-wrapper {
      z-index: 0;

      &:before {
        position: relative;
        z-index: -1;
      }
    }
  }
  @include for-phone-only {
    &__name {
      top: 75%;
    }
  }
}

.slick-track.remove-opacity {
  opacity: inherit !important;
}

.slick-slide {
  background-color: white !important;
}

.sticky-control {
  @include for-phone-only {
    height: 45px;
    width: 45px;
    position: fixed;
    right: 0;
    bottom: 60px;
    z-index: 2;
    background-color: black;
    border-radius: 28px 5px 5px 28px;

    .header-menu {
      &__icon {
        margin: 0;
        height: 45px;
        width: 45px;
      }
    }
  }
}

.menu {
  animation: slideOutRight 0.5s;

  &--open {
    z-index: $zindex-mobile-menu;
    display: block;
    width: 60%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    padding-bottom: 80px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $color-white;
    animation: slideInLeft 0.5s;
  }

  &--closed {
    display: block;
    width: 60%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: slideOutRight 0.5s;
  }

  .line-full-width {
    margin-right: 20px;
    margin-left: 20px;
  }

  @include for-phone-only {
    &--open {
      width: 100%;
    }
  }
}

.menu-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 25px $container-padding-horizontal-mobile 35px
    $container-padding-horizontal-mobile;

  &__icon {
    height: 24px;
    width: 24px;
    padding: 0;
    margin-right: 8px;
    font-size: 20px;
    background: none;
    border: 0;
    color: $icon-color;
    cursor: pointer;

    &:hover {
      color: $primary-color;
    }
  }
}

.menu-controls-logo {
  position: absolute;
  top: 12px;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
}

ul.menu-list {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .menu-subcategory {
    display: none;

    &.open {
      display: block;
      position: absolute;
      top: 100px;
    }
  }

  .menu-list__item-subcategory {
    display: none !important;
  }

  .menu-list__item-back {
    display: none !important;
  }

  .menu-list__item {
    padding-right: 8px;
    padding-left: 8px;

    .menu-submenu-open & {
      display: none;
    }

    &.open {
      .menu-submenu-open & {
        display: block;
      }

      .menu-subcategory {
        z-index: 1;
        display: block;
        position: absolute;
        top: 105px;
        left: 0;
        width: 100%;
        height: auto;
        padding-top: 20px;
        padding-left: 32px;
        background-color: $color-white;
      }

      .menu-list__item-subcategory {
        display: block !important;
        z-index: 1;
        position: absolute;
        top: 85px;
        left: 10px;
        background-color: $color-white;
      }

      .menu-list__item-back {
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        width: 72px;
        height: 72px;
        padding: 0;
        text-align: center;
        font-size: 16px;
        color: $icon-color;
      }
    }

    a,
    button {
      position: relative;
      display: block;
      width: 100%;
      padding: 8px 16px;
      font-size: 17px;
      line-height: 24px;
      text-decoration: none;
      background: none;
      border: 0;
      border-radius: 4px;
      text-align: left;
      color: $color-black;

      &:hover {
        background-color: #eaecf4;
        cursor: pointer;
        border: 0;
        outline: 0;
        background-color: #ffffff;

        .menu-list__item-icon {
          color: $primary-color;
        }
      }
    }
  }

  .menu-list__item-icon {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    font-size: 10px;
    color: $icon-color;
  }
}

.header-categories-dropdown {
  display: none;
  margin-right: 40px;
  margin-left: 0;

  @include tablet {
    display: block;
  }

  .dropdown-toggle {
    position: relative;
    background: none;
    border: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    color: $color-white;
    padding: 25px 0;

    &:after {
      display: none;
    }

    &:before {
      position: absolute;
      top: 50%;
      right: -22px;
      transform: translateY(-50%);
      font-size: 14px;
      font-family: "icomoon" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      @extend .icon-chevron-down;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      overflow: visible;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      overflow: visible;
    }
  }

  .menu-list {
    @include tablet {
      min-width: 254px;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .menu-list__item {
      position: relative;
      padding-right: 4px;
      padding-left: 4px;

      &:hover {
        @include tablet {
          color: $color-primary;
        }

        .menu-subcategory {
          @include tablet {
            display: block;
            position: absolute;
            top: 50%;
            right: -255px;
            min-width: 254px;
            transform: translateY(-30%);
            box-shadow: 0 0 4px $box-shadow;
          }
        }
      }

      a {
        &:hover {
          background-color: $grey150;
          color: $primary-color;
        }
      }

      .menu-subcategory {
        padding-top: 16px;
        padding-bottom: 16px;
        background-color: $color-white;
        border-radius: 6px;
      }
    }

    .menu-list__item a,
    .menu-list__item button {
      padding: 4px 16px;
      font-size: 14px;
    }
  }
}

.tag {
  padding: 2px 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background-color: #dde2e9;
  border-radius: 4px;
  text-transform: uppercase;
  color: $color-black;
  cursor: pointer;
}

.organic-tag-container {
  display: flex;
  background-color: #D0EFCF;
  padding: 2px 8px;
  line-height: 16px;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;

  .organic-icon {
    font-size: 12px;
    width: 16px;
    padding-right: 2px;
  }

  .organic-tag {
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: #055F53;
  }
}


.blue-button {
  width: auto;
  height: 100%;
  border-radius: 20px;
  margin: 40px auto 20px auto;
  padding: 5px 20px;
  background-color: $darkblue;
  color: $white;
  font-family: "montserratbold";
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
  &:focus {
    outline: 0;
  }
  &:disabled {
    opacity: 0.6;
    cursor: default;
    transform: none;
  }
  @include for-phone-only {
    font-size: 14px;
    margin: 10px auto;
    &:hover {
      transform: unset;
    }
  }
  &--standard-width {
    width: 350px;
  }
}

.black-button {
  width: auto;
  height: 100%;
  border-radius: 20px;
  margin: 40px auto 20px auto;
  padding: 5px 20px;
  background-color: $black;
  color: $white;
  font-family: "montserratbold";
  font-size: 22px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
  &:focus {
    outline: 0;
  }
  @include for-phone-only {
    font-size: 18px;
    margin: 10px auto;
    &:hover {
      transform: unset;
    }
  }
  &:disabled {
    opacity: 0.6;
    cursor: default;
    transform: none;
  }
}

.subheader-menu {
  width: 50%;
  margin: 15px auto -10px auto;
  display: flex;
  justify-content: space-between;
  font-family: "signikasemibold";
  font-size: 16px;
  text-transform: uppercase;

  &__item {
    cursor: pointer;
    opacity: 0.4;
    transition: all 0.2s;

    &--selected {
      color: $darkblue;
      opacity: 1;
    }

    &:hover {
      color: $darkblue;
      opacity: 1;
    }
  }
  @include for-phone-only {
    width: 80%;
  }
}

.text-input {
  border: 2px solid $black;
  border-radius: 25px;
  min-width: 250px;
  max-width: 33%;
  padding: 8px 20px;
  margin: 10px auto;
  font-family: "montserratmedium";
  font-size: 18px;
  // border-top: 1px solid $black;
  &:focus {
    outline: 0;
  }
  &::placeholder {
    text-transform: uppercase;
  }
}
// disable scroll when search bar is open
body.disable-scroll {
  overflow: hidden;
}

.search-bar {
  transform-origin: 18% 50%;
  z-index: 9;
  position: absolute;
  top: -10px;
  left: -23px;
  @include for-phone-only {
    transform-origin: 0%;
    left: -49px;
    top: -10px;
  }

  input {
    height: 50px;

    @include for-phone-only {
      font-size: 0.8em;
      min-width: 113px;
      height: 28px;
    }
  }
}

.select {
  width: 350px;
  height: 50px;
  border: 2px solid $black;
  border-radius: 25px;
  position: relative;
  padding: 5px 20px;
  margin: 20px auto;
  font-family: "montserratmedium";
  font-size: 22px;
  pointer-events: all;
  cursor: pointer;

  @include for-phone-only {
    width: 300px;
  }
  &__arrow {
    position: absolute;
    right: 3px;
    top: 3px;
    height: 40px;
    pointer-events: none;
  }
}

.form-label {
  font-family: "montserratbold";
  font-size: 22px;
  color: $darkblue;
  text-transform: uppercase;
  margin-top: 20px;
}

.tag-display {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  margin: 0px auto;

  @include for-phone-only {
    width: 300px;
  }

  &__text {
    border: 2px solid $black;
    border-radius: 25px;
    width: 100%;
    padding: 8px 20px;
    margin: 10px auto;
    font-family: "montserratmedium";
    font-size: 18px;
    text-transform: uppercase;
    text-align: left;
    max-width: 250px;
    overflow: hidden;
    overflow-wrap: break-word;
  }
  &__icon {
    margin-left: 5px;
    height: 50px;
    cursor: pointer;
  }
}

.tag-legend {
  font-size: 11px;
  margin-right: 55px;
  margin-top: -5px !important;
}

.social-display {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  margin: 0px auto 10px auto;

  @include small-phone {
    width: 300px;
  }
  .text-input {
    width: 100%;
  }
  &__help-text {
    position: relative;
    top: -5px;
    margin-top: -10px;
    margin-left: 50px;
    text-align: center;
  }
  &__error-text {
    position: relative;
    font-size: 12px;
    color: red;
    margin-left: 50px;
    margin-bottom: -10px;
    text-align: center;
  }
  &__icon {
    // margin-right: 5px;
    height: 50px;
    cursor: pointer;
  }
}

.text-area {
  border: 2px solid $black;
  border-radius: 25px;
  width: 350px;
  height: 270px;
  padding: 8px 20px;
  margin: 10px auto;
  font-family: "montserratmedium";
  font-size: 18px;
  resize: none;
  &::placeholder {
    text-transform: uppercase;
  }
  @include for-phone-only {
    width: 300px;
  }
}

.modal-overlay {
  position: absolute;
  height: 200vh;
  width: 100vw;
  top: -200px;
  background-color: #9c9c9c;
  z-index: 2;
  opacity: 0.8;
}

.modal-window {
  height: 300px;
  width: 400px;
  position: absolute;
  left: 50%;
  top: 120px;
  transform: translateX(-50%);
  background-color: $white;
  padding: 20px;
  z-index: 5;
  border: 2px solid $black;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
  &__text {
    font-family: "signikaregular";
    text-align: center;
  }
  &__large {
    height: 600px;
    width: 800px;
  }
  .blue-button {
    max-height: 40px;
    min-height: 38px;

    @include for-phone-only {
      min-height: 34px;
    }
  }
  @include for-phone-only {
    width: 80%;
    height: 200px;
    font-size: 16px;
  }
}

.modal-title {
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  letter-spacing: -0.03em;
  color: $color-black;
  margin: 0 0 31px;
}

.modal-subtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: $grey500;
  margin: 0 0 31px;
}

.modal-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    &:not(:last-child) {
      margin-bottom: 24px;

      @include tablet {
        margin-bottom: 0;
      }
    }
  }
}

.modal-checkboxes {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 -12px 24px;

  .checkbox {
    align-items: center;
    width: 50%;
    padding-right: 12px;
    padding-left: 12px;
    margin-bottom: 12px;

    @media (min-width: 375px) {
      width: calc(100% / 3);
    }

    @include tablet {
      width: 20%;
    }
  }

  .checkbox-input + .checkbox-box:after {
    width: 18px;
    height: 18px;
    bottom: 0;
    margin: auto 12px auto 0;
  }

  .checkbox-input + .checkbox-box:before {
    top: 3px;
    left: 3px;
    bottom: 0px;
    margin: auto;
  }

  .checkbox-label {
    font-size: 14px;
    line-height: 1.3;
    text-align: left;
  }
}

.multi-select {
  font-size: 14px;
  line-height: 1.3;

  .select__value-container {
    padding: 8px 12px;
  }

  .select__dropdown-indicator {
    width: 36px;
    height: 36px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 4px;
      bottom: 2px;
      margin: auto;
      display: block;
      width: 8px;
      height: 8px;
      border-right: 1px solid $color-black;
      border-bottom: 1px solid $color-black;
      transform: rotate(45deg);
      z-index: 1;
    }

    svg {
      display: none;
    }
  }

  .select__multi-value__label {
    font-size: 12px;
    font-weight: 700;
  }

  .select__clear-indicator,
  .select__indicator-separator {
    display: none;
  }
}

.alert {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  padding: 16px;
  font-size: 14px;
  line-height: 19px;
  background-color: $color-white;
  border-radius: 6px;
  box-shadow: 0 3px 6px $box-shadow-view;

  a {
    font-weight: 400;
  }
}

.dropdown-menu {
  padding: 0;
  border-radius: 6px;
  margin-left: -13px;
  margin-right: -40px;

  &.dropdown-arrow {
    &::before {
      position: absolute;
      z-index: 1;
      top: -7px;
      right: 35px;
      content: "";
      border-style: solid;
      border-width: 0 8px 9px 8px;
      border-color: transparent transparent $color-white transparent;

      @media (min-width: 356px) {
        right: 43px;
      }
    }

    .header-fixed &,
    .header-inverse & {
      &:after {
        position: absolute;
        z-index: 0;
        top: -9px;
        right: 35px;
        content: "";
        border-style: solid;
        border-width: 0px 8px 9px 8px;
        border-color: transparent transparent $grey150 transparent;

        @media (min-width: 356px) {
          right: 43px;
        }
      }
    }
  }
}

.divider {
  border-top: 1px solid $grey-line;
}

.input-item .input-item-prefix-inst {
  font-weight: 400;
  position: absolute;
  top: 13px;
  font-size: 14px;
  line-height: 1.3;
  color: #121318;
  left: 40px;
  font-family: "Open Sans", Arial, sans-serif;

  @include tablet-large {
    left: 44px;
  }
}

.input-item .input-item-prefix-fb {
  font-weight: 400;
  position: absolute;
  top: 13px;
  font-size: 14px;
  line-height: 1.3;
  color: #121318;
  left: 40px;
  font-family: "Open Sans", Arial, sans-serif;

  @include tablet-large {
    left: 44px;
  }
}

.exhibit-social {
  .input-item .input.input-social-fb {
    padding-left: 179px;

    @include tablet-large {
      padding-left: 183px;
    }
  }

  .input-item .input.input-social-inst {
    padding-left: 219px;

    @include tablet-large {
      padding-left: 224px;
    }
  }
}

.icon-play {
  &:before {
    position: relative;
    top: 0;
    left: 2px;
  }
}
