@import "../variables";
@import "../mixins/breakpoint";

.card-hotel {
  padding: 16px 36px;
  text-align: center;
  background-color: $color-white;
  border: 1px solid $grey150;
  position: relative;

  &__logo {
    width: 148px;
    height: 49px;
    margin-right: auto;
    margin-bottom: 12px;
    margin-left: auto;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }

  &__name {
    margin-bottom: 6px;
    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
  }

  &__district {
    color: $placeholder-color;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    min-height: 14px;
    margin-bottom: 6px;
  }

  &__untilDate {
    color: $darkblue;
    text-align: center;
    font-size: 10px;
    line-height: 14px; 
    min-height: 14px;
    margin-bottom: 6px;
  }

  &__upcoming {
    border-radius: 0px 0px 4px 4px;
    background: $darkblue;
    display: flex;
    padding: 2px 4px;
    width: fit-content;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);


    .upcoming-text {
      color: $color-white;
      text-align: center;
      font-size: 10px;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
  }
}

ul.card-hotel-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;

  > li {
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
    margin-bottom: 16px;

    @include mobile-xxl {
      width: 50%;
    }

    @media (min-width: 768px) {
      width: 33.333%;
    }

    @media (min-width: 1200px) {
      width: 25%;
    }

    @media (min-width: 1400px) {
      width: 20%;
    }
  }
}

.card-hotel-list__col {

  &.agolia-input {
    @include desktop-sm {
      flex: 1;
    }
  }

  @include tablet {
    width: 33.333%;
  }

  @include desktop-sm {
    width: 25%;
  }

  @media (min-width: 1400px) {
    width: 20%;
    padding-right: 8px;
    padding-left: 8px;
  }
}

.card-hotel-list__filters {
  justify-content: flex-end;

  .agolia-dropdown {
    max-width: 100% !important;
    left: 0 !important;
  }
}
