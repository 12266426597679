@mixin mobile-lg {
  @media (min-width: ($container-width-mobile-lg)) {
    @content;
  }
}

@mixin mobile-xxl {
  @media (min-width: ($container-width-mobile-xxl)) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: ($container-width-tablet)) {
    @content;
  }
}

@mixin tablet-large {
  @media (min-width: ($container-width-tablet-large)) {
    @content;
  }
}

@mixin desktop-sm {
  @media (min-width: ($container-width-desktop-small)) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: ($container-width-desktop)) {
    @content;
  }
}

@mixin desktop-md {
  @media (min-width: ($container-width-desktop-md)) {
    @content;
  }
}

@mixin desktop-lg {
  @media (min-width: ($container-width-desktop-lg)) {
    @content;
  }
}
