@import "../../../styles/variables";
@import "../../../styles/mixins/breakpoint";

.notifications {
  max-height: 394px;
  overflow-y: auto;
  min-width: 290px;
  width: 100%;
  padding: 24px 0 8px;
  border-radius: 10px;

  @include tablet {
    min-width: 427px;
    padding: 24px 0 8px;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4px;
    padding-right: 12px;
    padding-left: 12px;
    margin-bottom: 24px;

    @include tablet {
      padding-right: 24px;
      padding-left: 24px;
    }
  }
}

.notification-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.notification {
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__date {
    font-size: 12px;
    line-height: 1;
    color: $grey200;
  }

  &__item {
    padding-top: 20px;
    padding-right: 12px;
    padding-bottom: 16px;
    padding-left: 12px;

    @include tablet {
      padding-right: 24px;
      padding-left: 24px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $grey150;
    }

    &.unread {
      background-color: $grey-line;
    }

    &:hover {
      background: $grey-line;
    }
  }

  &__link {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    color: $primary-color;
    transition-property: color;
    transition-duration: $transition-duration;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      color: $primary-color-hover;
      transition-property: color;
      transition-duration: $transition-duration;
    }
  }

  &__text {
    margin-bottom: 2px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: #121318;

    a {
      color: $blue400;
      text-decoration: none;
    }
  }

  &__type {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    letter-spacing: -0.3px;
    color: #98a0ab;
  }
}

.notification-status {
  flex-shrink: 0;
  width: 5px;
  height: 5px;
  margin-right: 7px;
  border-radius: 50%;
  background-color: #0a66c2;
}
