@import "../../../styles/variables.scss";
@import "../../../styles/mixins/breakpoint";

.brandCard {
  height: 100%;
  max-width: 219px;
  width: 100%;
  min-height: 212px;
  display: flex;
  flex-direction: column;
  text-align: center;
  text-decoration: none;
  background-color: $color-white;
  box-shadow: 0px 3px 6px $box-shadow-view;
  border-radius: 6px;
  margin: 0;
  padding: 12px;
  overflow: hidden;
  cursor: pointer;
  transition-property: box-shadow;
  transition-duration: $transition-duration;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 3px 6px $box-shadow;
    transition-property: box-shadow;
    transition-duration: $transition-duration;
  }

  &__container {
    display: flex;
    flex-direction: column;
    min-height: 118px;
    height: 100%;
    position: relative;
    border-top: none;
    background-color: $bkcg-white;
    overflow: hidden;
    padding: 0 6px;
  }

  &__content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: auto;
  }

  &__favorites {
    position: absolute;
    right: 12px;
    top: 13px;
    width: 16px;
    height: 14px;
    color: $favorite;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__background {
    overflow: hidden;
    width: 84px;
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto 12px;
  }

  &__image {
    width: 46px;
    height: 43px;
    margin-right: 4px;
    overflow: hidden;
    flex-basis: 46px;
    flex-grow: 0;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &__info {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    justify-content: flex-start;
  }

  &__type {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.03em;
    color: $color-black;
    margin: 3px 0 2px;
  }

  &__date {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.03em;
    color: $placeholder-color;
    margin: 0;
  }

  &__tabs {
    margin-bottom: 6px;

    @include tablet-large {
      margin-bottom: 4px;
    }
  }

  &__availableIn {
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      display: inline;
    }

    &-title {
      color: $opacity-text-color;
      margin-right: 10px;
    }

    &-text {
      font-weight: 600;
    }

    @include tablet-large {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__countries {
    color: $color-black;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.02em;

    .country {
      border-right: 1px solid $background-color-grey;
      padding: 0 4px;

      &:last-child {
        border: none;
      }
    }
    
  }

  &__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.03em;
    color: $color-black;
    margin: 0 0 16px;
  }

  &__text {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.3px;
    color: $placeholder-color;
    margin: 0 0 auto;
    height: 100%;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &.subscription {
    display: inline-flex;
    flex-direction: column;
    width: 219px;
    height: 167px;
    padding: 12px 12px 20px;
    white-space: normal;

    .brandCard {
      padding: 15px 24px 32px;

      &__name {
        height: 57px;
        margin: 0 0 auto;
        padding: 0 24px;

        span {
          display: inline-block;
          vertical-align: middle;
        }

        &:before {
          content: "";
          display: inline-block;
          vertical-align: middle;
          width: 0;
          height: 100%;
        }
      }

      &__background {
        width: 148px;
        height: 49px;
      }
    }
  }

  &.stats {
    display: block;
    width: 100%;
    max-width: 100%;
    height: 385px;
    text-align: left;
    margin: 0;
    padding: 24px;
    cursor: default;

    @include tablet {
      height: 228px;
    }

    @include mobile-xxl {
      // max-width: 219px;
    }

    .brandCard {
      &__name {
        width: 125%;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.04em;
        text-align: left;
        text-transform: uppercase;
        color: $placeholder-color;
      }

      &__container {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @include tablet {
          flex-direction: row;
        }
      }

      &__row {
        display: flex;
        justify-content: space-between;
        margin: 0 0 10px;

        &:last-child {
          margin: 0;
        }
      }

      &__key,
      &__percentage {
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.03em;
        color: $color-black;
      }

      &__key {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 8px;
          left: -9px;
          display: block;
          width: 4px;
          height: 4px;
          background-color: $primary-color;
          border-radius: 50%;
          z-index: 1;
        }
      }
    }
  }
}
