.partner-header {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 309px;
    padding-bottom: 32px;
    text-decoration: none;
  
    @include tablet {
      min-height: 500px;
      padding-bottom: 60px;
    }
  
    a {
      text-decoration: none;
    }
  
    > * {
      position: relative;
      margin-top: 0;
  
      @include tablet {
        margin-top: auto;
      }
    }
  
    &__back {
      margin-top: 100px;
      margin-bottom: 30px;
  
      span {
        text-decoration: none !important;
        font-weight: 700;
        color: $color-black;
      }
  
      // @include tablet {
      //   margin-top: 50px;
  
      // }
    }
  
    &__description {
      font-family: "signikaregular";
      font-size: 22px;
      margin-bottom: 40px;
      text-align: center;
  
      @include for-phone-only {
        font-size: 14px;
        text-align: center;
        margin: 0px 10px;
      }
    }
}

.partner-header-title {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 32px;
    font-size: 37px;
    color: $color-white;
  
    @include tablet {
      font-size: 62px;
      line-height: 72px;
    }
}

.partner-info__container {
    display: flex;
    gap: 40px;

    .partner-background {
        width: 240px;
        height: 240px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 4px;
        border: 1px solid #E2E8F0;
    }
    
    .partner-header__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;

        .partner-header-title {
            margin-bottom: 0;
            font-weight: 700;
            font-size: 36px;
            color: $color-black;
        
            @include tablet {
                font-size: 48px;
                line-height: 48px;
            }
        }
    
        .partner-header-description {
            margin-top: 16px;
            margin-bottom: 40px;
            font-weight: 700;
            font-size: 16px;
            color: $color-black;
        
            @include tablet {
                font-size: 20px;
            }
        }
    }
}

.exhibitor-list-header {

    margin-bottom: 24px;
    display: flex;
    gap: 20px;
    align-items: center;

    .exhibitor-list-header__title {
        color: $color-black;
        font-family: $font-family-secondary;
        font-size: 32px;
    }

    .exhibitor-list-header__brands_count {
        color: $placeholder-color;
        font-family: $font-family-primary;
        font-size: 16px;
        font-weight: 600;
    }
}

.popular-products-section {
  padding-bottom: 80px;
}

.popular-products-title {
  margin-bottom: 40px;
  text-align: center;
}

.popular-products-container {
  .products-swiper-button-prev, .products-swiper-button-next {
    z-index: 1;
    background-color: $grey;
  }

  .swiper-pagination-custom {
    z-index: 0;
  }
}

