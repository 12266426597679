.category-header {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 250px;
  padding-bottom: 32px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  text-decoration: none;

  @include tablet {
    min-height: 500px;
    padding-bottom: 80px;
  }

  a {
    text-decoration: none;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  > * {
    position: relative;
    margin-top: 0;

    @include tablet {
      margin-top: auto;
    }
  }

  &__back {
    margin-top: 100px;
    margin-bottom: 50px;

    span {
      text-decoration: none !important;
      font-weight: 700;
      color: #fff;
    }

    // @include tablet {
    //   margin-top: 50px;

    // }
  }

  &__description {
    font-family: "signikaregular";
    font-size: 22px;
    margin-bottom: 40px;
    text-align: center;

    @include for-phone-only {
      font-size: 14px;
      text-align: center;
      margin: 0px 10px;
    }
  }
}

.category-header-title {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 32px;
  font-size: 37px;
  color: $color-white;

  @include tablet {
    font-size: 62px;
    line-height: 72px;
  }
}

ul.category-taglist {
  display: none;
  width: calc(100% + 8px);
  margin: 0 -4px 4px;
  font-size: 0;
  min-height: 32px;

  &--mobile {
    display: block;
  }

  @media (min-width: 992px) {
    display: block;
    margin: 0px -4px 0;

    &--mobile {
      display: none;
    }
  }
}

ul.category-taglist-mobile {
  display: block;
  width: calc(100% + 8px);
  padding-top: 16px;
  background-color: $background-color-grey;
  font-size: 0;
  min-height: 32px;

  &--mobile {
    display: block;
  }

  @media (min-width: 992px) {
    display: none;
    margin: 12px -4px 0;

    &--mobile {
      display: none;
    }
  }
}

li.category-tag {
  display: inline-block;
  vertical-align: top;
  background-color: $color-white;
  border-radius: 4px;
  margin: 0 4px 8px;
  padding: 4px 30px 4px 8px;
  white-space: nowrap;
  position: relative;

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $color-black;
    cursor: default;
  }

  button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    left: auto;
    right: 4px;
    display: block;
    width: 16px;
    height: 16px;
    font-size: 11px;
    background-color: $blue-cross;
    border: none;
    border-radius: 50%;
    padding: 0;
    color: $color-white;
    cursor: pointer;
  }
}

ul.clear-tag-container {
  display: none;
  margin: 0 -4px 4px;
  font-size: 0;
  min-height: 32px;

  &--mobile {
    display: block;
  }

  @media (min-width: 992px) {
    display: block;
    margin: 0px -4px 0;

    &--mobile {
      display: none;
    }
  }
}

ul.clear-tag-container-mobile {
  display: block;
  padding-top: 16px;
  background-color: $background-color-grey;
  font-size: 0;
  min-height: 32px;

  &--mobile {
    display: block;
  }

  @media (min-width: 992px) {
    display: none;
    margin: 12px -4px 0;

    &--mobile {
      display: none;
    }
  }
}

li.clear-tag {
  display: inline-block;
  vertical-align: top;
  background-color: $icon-color;
  border-radius: 4px;
  margin: 0 4px 8px;
  padding: 4px 8px;
  white-space: nowrap;
  position: relative;
  cursor: pointer;

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $color-white;
  }

  &:hover {
    background-color: #F1F3F6;
    span {
      color: $blue400;
    }
  }
}  

.category-tag-container {
  display: flex;
}

.category-filter-button {
  z-index: 1;
  position: absolute;
  top: auto;
  left: auto;
  right: 15px;
  bottom: 19px;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0;
  cursor: pointer;

  span,
  svg {
    display: inline-block;
    vertical-align: middle;
  }

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: $color-white;
    margin: 0 12px 0 0;

    &.icon-filter {
      font-size: 24px;
      font-weight: 300;
      margin: 0;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    fill: $color-white;
    color: $color-white;
  }

  @media (min-width: 992px) {
    display: none;
  }
}

.exhibitor-list, .product-list {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 16px);
  margin: 0 -8px 24px;

  .tag-list {
    margin-top: 8px;
    margin-bottom: 8px;

    @include tablet-large {
      margin-bottom: 12px;
      margin-top: 12px;
    }
  }

  .product-tag-list {
    margin-top: 8px;
    margin-bottom: 8px;

    @include tablet-large {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  .tag {
    display: inline-block;
    background-color: $tag-color;
    border-radius: 4px;
    padding: 2px 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-transform: none;
    transition: $transition-duration;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 10px;

      @include tablet-large {
        margin-right: 8px;
      }
    }

    @include tablet-large {
      font-size: 14px;

      &:hover {
        background-color: $blue-tag;
      }
    }
  }

  .immutable-tag-container {
    display: inline-block;
    vertical-align: middle;

    &:not(:last-child) {
      margin-right: 10px;

      @include tablet-large {
        margin-right: 8px;
      }
    }

    .organic-tag-container {
      display: flex;
      background-color: #D0EFCF;
      border-radius: 4px;
      padding: 2px 8px;
      font-size: 12px;
      line-height: 16px;
      align-items: center;
      transition: $transition-duration;
      cursor: pointer;
  
      @include tablet-large {
        font-size: 14px;
  
        &:hover {
          background-color: $blue-tag;
        }
      }
  
      .organic-icon {
        width: 16px;
        padding-right: 2px;
      }
    
      .organic-tag {
        display: inline-block;
        font-weight: 700;
        text-transform: none;
        color: #055F53;
        @include tablet-large {
          font-size: 14px;
        }
      }
    }
  }

  @include for-phone-only {
    width: 100%;
    margin: 0;
  }
}

.exhibitor-clickable, .product-clickable, .partner-clickable {
  cursor: pointer;
}

.partner-card {
  width: calc(50% - 40px);
  height: 187px;
  flex: none;
  background-color: $color-white;
  margin: 0 20px 40px;
  border: 1px solid #d5dae2;
  border-radius: 6px;
  overflow: hidden;

  @include for-phone-only {
    width: 100%;
    margin: 0 0 16px;
  }

  @include tablet-large {
    transition: $transition-duration;

    &:hover {
      box-shadow: 0px 6px 12px $box-shadow-card;
    }
  }

  .partner__brand-container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    min-height: 185px;
    z-index: 1;

    .brand-container__logo {
      flex: none;
      width: 185px;
      height: 185px;
      overflow: hidden;
      // width: fit-content;

      .image-wrapper__image {
        width: 100%;
        background-size: cover !important;
        left: 0;
      }
    }

    .brand-contianer__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      padding: 20px;
      width: 100%;

      .brand-contianer__info__label {
        color: $color-black;
        font-size: 23px;
        font-weight: 600;
        line-height: normal;
        @include tablet{
          font-size: 24px;
        }
      }

      .brand-count {
        color: $placeholder-color;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        margin-top: 12px;
      }
    }
  }
}

.product-card {
  width: calc(33% - 16px);
  flex: none;
  background-color: $color-white;
  margin: 0 8px 16px;
  border: 1px solid #d5dae2;

  @include for-phone-only {
    width: 100%;
    margin: 0 0 16px;
  }

  @include tablet-large {
    transition: $transition-duration;

    &:hover {
      box-shadow: 0px 6px 12px $box-shadow-card;
    }
  }

  &:hover {
    .exhibitor-card__unfollow {
      display: block;
    }
  }

  &__unfollow {
    width: auto !important;
    position: absolute;
    right: 12px;
    bottom: 12px;

    @include tablet {
      display: none;
    }
  }
}

.exhibitor-card {
  width: calc(50% - 16px);
  flex: none;
  background-color: $color-white;
  margin: 0 8px 16px;
  border: 1px solid #d5dae2;

  @include for-phone-only {
    width: 100%;
    margin: 0 0 16px;
  }

  @include tablet-large {
    transition: $transition-duration;

    &:hover {
      box-shadow: 0px 6px 12px $box-shadow-card;
    }
  }

  &:hover {
    .exhibitor-card__unfollow {
      display: block;
    }
  }

  &__unfollow {
    width: auto !important;
    position: absolute;
    right: 12px;
    bottom: 12px;

    @include tablet {
      display: none;
    }
  }
}

.exhibitor-background {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 112px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $grey100;
}

.product-background {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 309px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $grey100;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.200521) 45.83%, rgba(0, 0, 0, 0) 92.71%);
  }

  .product__brand-container {
    position: absolute;
    bottom: 0;
    left: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 66px;
    z-index: 1;

    .brand-container__logo {
      flex: none;
      height: 66px;
      width: 66px;
      border-radius: 50%;
    }

    .brand-container__text {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: $color-white;
    }
  }
}

.similar_exhibitor-upper {
  padding: 24px 50px 16px 24px;
  display: flex;

  &__logo {
    flex: none;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin-right: 12px;

    .image-wrapper__image {
      width: 100%;
      left: 0;
      border-radius: 4px;
      border: 1px solid #E2E8F0;
    }
  }

  .similar_exhibitor-upper-info {
    flex: 1;
    float: left;
    overflow-x: hidden;
  
    &__title {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.3px;
      white-space: nowrap;
    }

    &__description {
      font-weight: 600px;
      font-size: 14px;
      margin-bottom: 10px;
    }

    .tag-list {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

.exhibitor-upper {
  display: flex;
  justify-content: left;
  flex-direction: column;
  min-height: 110px;
  border-bottom: none;
  position: relative;
  padding: 0 16px;

  &__logo {
    flex: none;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin-right: 12px;
    margin-top: -60px;

    .image-wrapper__image {
      width: 100%;
      background-size: cover !important;
      box-shadow: 0px 6px 12px 0px rgba(74, 77, 87, 0.24);
      left: 0;
      border-radius: 4px;
    }
  }

  @include for-phone-only {
    &__logo {
      height: 60px;
      width: 60px;
      margin-top: -40px;
    }
  }
  @include tablet-large {
    padding: 0 24px;
  }
}

.product-upper {
    display: flex;
    justify-content: left;
    align-items: center;
    min-height: 110px;
    border-bottom: none;
    position: relative;
    padding: 16px 16px 0;
    min-height: 10px;
  
    &__logo {
      flex: none;
      height: 80px;
      width: 80px;
      border-radius: 50%;
      margin-right: 12px;
    }
  
    @include for-phone-only {
      &__logo {
        height: 60px;
        width: 60px;
      }
    }
    @include tablet-large {
      padding: 20px 20px 0;
    }
}

.exhibitor-upper-info {
  flex: 1;
  float: left;
  overflow-x: hidden;
  margin-top: 20px;

  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: -0.3px;
    white-space: nowrap;

    @include tablet-large {
      font-size: 20px;
      line-height: 21px;
    }
  }
  &__description {
    font-weight: 600px;
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.product-upper-info {
  flex: 1;
  float: left;
  overflow: hidden;
  
  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: -0.3px;

    @include tablet-large {
      font-size: 20px;
      line-height: 27px;
    }
  }
  &__description {
    font-weight: 400px;
    font-size: 14px;
  }
}

.exhibitor-upper-favorite {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 16px;
  background: none;
  border: 0;
  padding: 0;

  .icon-star-fill {
    color: $favorite;
  }
}

.product-lower {
  display: flex;
  justify-content: space-between;
  min-height: 79px;
  border-top: none;
  padding: 0 16px 24px;
  flex-direction: column;
  gap: 20px;

  .remove-excess-height {
    margin-bottom: -2px;
  }

  &__country {
    padding: 8px 0;
  }

  &__arrow {
    height: 25px;
    width: 25px;
  }

  @include tablet-large {
    padding: 0 20px 20px;
  }
}

.exhibitor-lower {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 79px;
  border-top: none;
  padding: 0 16px 24px;

  .remove-excess-height {
    margin-bottom: -2px;
  }

  &__country {
    font-size: 16px;
    margin-right: 5px;
  }

  &__arrow {
    height: 25px;
    width: 25px;
  }

  @include tablet-large {
    padding: 0 24px 20px;
  }

  .exhibitor-partner {
    display: flex;
    margin-top: 10px;
    
    .partner__background {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border: 1px solid #E2E8F0;
    }

    .partner__name {
      color: $color-black;
      font-size: 14px;
      font-weight: 600;
      padding-left: 8px;
    }
  }
}

.exhibitor-lower__available-in {
  font-size: 16px;
  margin-right: 5px;
}

.exhibitor-text, .product-text {
  color: $opacity-text-color;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02em;
  font-weight: 400;
  word-break: break-all;
}

.exhibitor-no-results {
  display: block;
  width: 100%;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.03em;
  text-align: left;
  color: $color-black;
  margin: 0 0 20px;
}

.subcategory-list {
  > * {
    @include mobile-xxl {
      width: 50%;
    }

    @include tablet-large {
      width: 33.3333%;
    }
  }
}

.subcategories-card {
  @include for-phone-only {
    min-width: 100%;

    .home-category-list-card__content {
      margin-bottom: 32px;
    }
    .home-category-list-card__background {
      width: 100%;
    }
    .home-category-list-card__icon {
      max-height: 80px;
      max-width: 80px;
    }
    .home-category-list-card__name {
      font-weight: 700;
    }
  }

  .home-category-list-card__background {
    display: block;
    min-height: 202px;
  }

  .home-category-list-card__icon {
    transform-origin: center center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .home-category-list-card__name {
    font-weight: 400;
    font-size: 18px;
  }
}

.products {
  &__available-in {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $grey500;
  }

  &__available-in-edit {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 6px;
    color: $grey500;
  }

  &__country {
    margin-bottom: 40px;
    font-weight: 600;
    color: $color-black;
  }

  &__title {
    margin-bottom: 12px;
  }

  .exhibit-title__logo-edit {
    color: $placeholder-color;
  }

  .add-more {
    .add-more__label {
      background-color: rgba(0, 0, 0, 0);

      @media (min-width: 1024px) {
        height: 330px;
      }
    }
  }

  .text-error {
    font-size: 12px;
    line-height: 16px;
  }
}

.products-images {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.products-image-item {
  padding: 0 8px;
  margin-bottom: 32px;
  width: 50%;

  @include tablet {
    width: 25%;
    margin-bottom: 50px;
  }
}

.products-image {
  position: relative;
  display: block;
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;

  @media (min-width: 1024px) {
    height: 330px;
    background-size: cover;
    margin-bottom: 16px;
  }

  &__desc {
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    color: $grey500;
    letter-spacing: -0.3px;
    white-space: pre-line;
  }

  &__name {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding-top: 0;
    padding-bottom: 16px;
    color: $color-black;
    letter-spacing: -0.5px;

    @media (min-width: 992px) {
      font-size: 16px;
    }
  }
}

.category-filters {
  position: fixed;
  top: 0;
  left: auto;
  right: -150vw;
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color-black-00, 0.5);
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  overflow-y: auto;

  &.show {
    right: 0;
    opacity: 1;
    visibility: visible;
  }

  @media (min-width: 992px) {
    position: static;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}

.category-filters-content {
  display: block;
  width: 100%;
  max-width: 306px;
  height: 100%;
  background-color: $color-white;
  margin: 0 0 0 auto;
  padding: 47px 0;
  position: relative;
  overflow-y: auto;

  @media (min-width: 992px) {
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0);
    margin: 0;
    padding: 0;
  }
}

.category-filters-wrap {
  display: block;
  width: 100%;
  max-width: 246px;
  padding-bottom: 30px;
}

.category-filters-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: $color-black-00;
  margin: 0 0 24px;
  padding: 0 52px;

  @media (min-width: 992px) {
    padding: 0 28px;
  }
}

ul.category-filters-list {
  margin: 0;
  padding: 16px 0 0 24px;
  list-style-type: none;

  @media (min-width: 992px) {
    padding: 16px 0 0 0;
  }
}

.category-filters-text,
.category-filters-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: $color-black;

  @media (min-width: 992px) {
    font-size: 14px;
  }
}

.category-filters-text {
  background: none;
  border: 0;
  
  padding: 0 18px 16px;
  cursor: pointer;

  @media (min-width: 992px) {
    padding: 0 17px 16px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 6px;
    display: block;
    width: 5px;
    height: 5px;
    background-color: $primary-color;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    transition: $transition-duration;
    z-index: 1;
  }

  &.active {
    position: relative;
    color: $blue;

    &:before {
      opacity: 1;
      visibility: visible;
    }
  }
}

.subcategory-filters-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: $color-black;

  @media (min-width: 992px) {
    font-size: 14px;
  }
}

.catogory-filters-follow-border {
  border-bottom: 1px solid $grey-border;
}

.category-filters-item {
  transition: $transition-duration;
  position: relative;
  margin: 0 0 16px;
  cursor: pointer;

  .category-filters-btn {
    display: flex;
    align-items: center;

    .category-filters-img-wrapper {
      overflow: hidden;
      display: inline-block;
      min-width: 28px;
    }

    .category-filters-img {
      width: 100%;

      svg {
        vertical-align: middle;

        * {
          fill: none;
          stroke: currentColor;
        }
      }
    }

    .category-filters-img-empty {
      padding-left: 28px;
    }

    .category-filters-icon {
      margin-left: auto;
    }
  }

  &.active {
    color: $blue;

    &:before {
      opacity: 1;
      visibility: visible;
    }
  }

  @media (min-width: 992px) {
    margin: 0 0 12px;

    &:hover {
      color: $primary-color;
    }
  }

  .category-filter-follow {
    padding-left: 24px;
    @media (min-width: 992px) {
      padding-left: 0;
    }
  }
}

ul.category-filters-inner {
  margin: 0;
  padding: 16px 0 0 23px;
  list-style-type: none;

  .subcategory-filters-btn {
    display: flex;
    align-items: center;
    margin: 0 0 16px;
    color: $color-black;

    &:last-child {
      margin: 0;
    }

    .category-filters-img-wrapper {
      overflow: hidden;
      display: inline-block;
      min-width: 24px;

      svg {
        vertical-align: middle;
        * {
          fill: none;
          stroke: currentColor;
        }
      }
    }

    .subcategory-filters-text {
      color: $blue-filter;


      &.active {
        color: $primary-color;
      }
  
      &:last-child {
        margin: 0;
      }
    }
  
    .subcategory-filters-img {
      width: 100%;
    }
  
    .subcategory-filters-img-empty {
      padding-left: 24px;
    }

    @media (min-width: 992px) {
      &:hover {
        color: $primary-color;

        .subcategory-filters-text {
          color: $primary-color;
        }
      }

      margin: 0 0 8px;

      &:last-child {
        margin: 0;
      }
    }

    &.category-active {
      color: $primary-color;
    }
  }
  
}

.category-filters-close {
  position: absolute;
  top: 26px;
  left: auto;
  right: 24px;
  display: block;
  width: 24px;
  height: 24px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0;
  cursor: pointer;
  z-index: 1;
  font-size: 22px;
  color: $icon-color;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    stroke: $placeholder-color;
  }

  @media (min-width: 992px) {
    display: none;
  }
}

.exihibitor-list-header {
  font-weight: 700;
  font-size: 32px;
  color: $grey500;
  margin-bottom: 20px;
}

.product-list-header {
  font-weight: 700;
  font-size: 32px;
  color: $grey500;
  margin-bottom: 20px;
}

.category-title, .news-feed-title {
  padding-left: 29px;
}
