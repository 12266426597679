@import "../styles/variables";
@import "../styles/mixins/breakpoint";

#root,
.layout {
  min-height: 100%;
  height: 100%;
}

.layout {
  display: flex;
  flex-direction: column;
}

// Header
.header {
  flex-grow: 0;
  z-index: $zindex-header;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  border-bottom: 1px solid rgba(221, 226, 233, 0.4);
  color: $color-black;
  transition-property: background-color, color;
  transition-duration: $transition-duration;

  @include desktop {
    padding-right: 70px;
    padding-left: 70px;
  }

  &.header-fixed {
    background-color: $color-white;
    color: $color-black;
    transition-property: background-color, color;
    transition-duration: $transition-duration;

    [class^="icon-"],
    .header-categories-dropdown .dropdown-toggle,
    .header-logout,
    .header-menu-link {
      color: $color-black;
    }

    .section-hero-header-logo {
      display: none;
    }

    .section-hero-header-logo-inverse {
      display: block;
    }
  }

  &.header-mini {
    border-bottom: 1px solid #d8dae7;
  }

  .section-hero-header-logo-inverse {
    display: none;
  }

  &.header-default {
    .header-menu-link {

      &.selected {
        position: relative;
        font-weight: 700;

        &:before {
          content: "";
          position: absolute;
          right: 0;
          bottom: -6px;
          left: 0;
          width: 5px;
          height: 5px;
          margin-right: auto;
          margin-left: auto;
          border-radius: 50%;
          background-color: $color-white;
        }
      }
    }

    &.header-fixed {
      .header-menu-link {
        &.selected {
          &::before {
            background-color: $color-black;
          }
        }
      }
    }
  }

  &.header-inverse {
    background-color: $color-white;
    transition-property: background-color, color;
    transition-duration: $transition-duration;
    color: $color-black;

    .dropdown-toggle,
    .header-menu-link {
      color: $color-black;

      &.selected {
        position: relative;
        font-weight: 700;

        &:before {
          content: "";
          position: absolute;
          right: 0;
          bottom: -6px;
          left: 0;
          width: 5px;
          height: 5px;
          margin-right: auto;
          margin-left: auto;
          border-radius: 50%;
          background-color: $color-black;
        }
      }
    }

    .header-logout {
      color: $color-black;
    }

    .section-hero-header-logo {
      display: none;
    }

    .section-hero-header-logo-inverse {
      display: block;
    }
  }
}

.header-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1366px;
  min-height: 74px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;

  @include tablet {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.header-inner-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.header-menu {
  display: flex;
  position: relative;
  align-items: center;

  &__icon-wrap {
    position: relative;
  }

  &__icon {
    height: 50px;
    margin: 0;
    cursor: pointer;
    background: none;
    border: 0;
    padding: 0 9px;
    font-size: 20px;
    color: $color-white;

    .header-fixed &,
    .header-inverse & {
      color: $color-black;
    }
  }

  &__sign-out {
    font-size: 16px;
    color: $darkblue;
    cursor: pointer;
    margin-left: -20px;
    float: left;
    clear: both;
    width: 60px;
    margin-right: 5px;

    @include for-phone-only {
      font-size: 12px;
    }
  }
}

.header-menu-toggle {
  @include tablet {
    display: none;
  }
}

.header-menu-link {
  display: none;
  font-size: 14px;
  line-height: 20px;
  color: $color-white;
  text-decoration: none;
  margin-right: 24px;

  @include tablet {
    display: block;
  }

  &:last-of-type {
    margin-right: auto;
  }

  &.header-menu-link-account {
    margin-right: 16px;
    margin-left: auto;
  }
}

.header-logout {
  background: none;
  border: 0;
  padding: 0;
  font-size: 20px;
  margin-left: 16px;
  color: $color-white;
  cursor: pointer;
}

// Footer
.footer {
  background: $color-black url("/assets/images/footer-mobile.jpg") center/cover
    no-repeat;
  position: relative;
  .display-when-large{
    display: none;
    @include tablet{
      display: block;
    }
  }
  .display-when-small{
    display: block;
    @include tablet{
      display: none;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black-secondary;
    opacity: 0.8;
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0 48px;
  }

  &__logo {
    flex-shrink: 0;
  }

  &__main {
    display: flex;
    justify-content: space-between;
    padding: 60px 2px 40px;

    &--row {
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    ul {
      margin-top: 0;
      margin-bottom: 12px;
      padding-right: 0;
      padding-left: 0;
      text-align: center;
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    display: inline;
  }

  &__item {
    display: block;
    margin-bottom: 12px;
    line-height: 0;
  }

  &__link {
    display: inline-block;
    max-width: 144px;
    font-size: 12px;
    line-height: 16px;
    color: $color-white;
    text-decoration: none;
  }

  @include tablet-large {
    background-image: url("/assets/images/footer.jpg");

    &__link {
      font-size: 14px;
      line-height: 19px;
      max-width: 210px;
    }

    &__item {
      margin-bottom: 12px;
    }

    &__list {
      min-width: 20%;

      &--1 {
        margin-left: 3% !important;
      }

      &--2 {
        margin-left: 12% !important;
      }
    }
  }

  &--small {
    flex-grow: 0;
    text-align: center;
    padding: 50px 15px;
    font-size: 14px;
    background: transparent;

    &:before {
      display: none;
    }

    @include tablet {
      padding: 50px 15px 35px;
    }
  }

  &--small & {
    &__arrow {
      height: 30px;
      width: 30px;
      margin: 20px auto 18px auto;
      cursor: pointer;

      @include for-phone-only {
        height: 25px;
        width: 25px;
      }
    }

    &__logo {
      width: 126px;
      height: auto;
      margin-right: auto;
      margin-bottom: 16px;
      margin-left: auto;

      @include tablet {
        width: 141px;
        height: 34px;
      }
    }
  }
}

.footer__copyright {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.3px;

  @include tablet {
    font-size: 14px;
  }
}

// Sections
.page-wrapper {
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  width: 100%;
  overflow: hidden;

  &.bg-fade-out{
    &::before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -2;
      background-repeat: repeat;
      background: url('/assets/images/bg-svg.svg');
    }
    &::after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background: linear-gradient(to bottom, transparent, $background-color-grey 75%, $background-color-grey);
    }
  }
}

.page-head {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 280px;
  padding-bottom: 48px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  @include tablet {
    min-height: 500px;
    padding-bottom: 72px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  > * {
    position: relative;
    margin-top: auto;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-top: 93px;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-top: 93px;
    }

    @include tablet {
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin-top: 305px;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-top: 305px;
      }
    }

    @include tablet-large {
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin-top: 230px;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-top: 230px;
      }
    }
  }
}

.page-head-title {
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 32px;
  line-height: 37px;
  letter-spacing: -0.03em;
  color: $color-white;

  @include tablet-large {
    font-size: 62px;
    line-height: 72px;
    margin-bottom: 16px;
  }
}

.grey-overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 150vh;
  width: 100vw;
  background-color: #9c9c9c;
  z-index: 2;
  opacity: 0.6;
}

.bold-header {
  margin-top: 20px;
  font-family: "montserratbold";
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  @include for-phone-only {
    font-size: 20px;
  }
}

.light-header {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: "montserratlight";
  font-size: 26px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 1px solid $black;
  @include for-phone-only {
    font-size: 20px;
  }
}

.line-centered {
  width: 33%;
  margin: 20px auto 0px auto;
  text-align: center;
  border-top: 1px solid $black;
}

.small-line-centered {
  width: 350px;
  margin: 10px auto 10px auto;
  text-align: center;
  border-top: 1px solid #b5bcc6;
}

.line-full-width {
  margin: 32px auto;
  border-top: 1px solid #e0e2ee;
}

.ellipses {
  padding: 0px 5px;
}
.loader-container {
  height: 100vh;
  width: 100vw;
  background-color: $white;
  position: fixed;
  z-index: 5;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}
.loader {
  position: relative;
  height: 150px;
  width: 150px;
  margin: auto;
  top: 35%;
  // transform: translate(-50%, -50%);
  &__one {
    height: 150px;
    width: 150px;
    position: absolute;
    z-index: 1;
    animation: pulse 3s linear 0s infinite;
  }
  &__two {
    height: 150px;
    width: 150px;
    position: absolute;
    animation: rotate 3s ease-in-out infinite;
  }
}

.image-banner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -5px;

  @include tablet-large {
    margin-right: -8px;
    margin-left: -8px;
  }

  .image-wrapper {
    width: 50%;
    min-height: 200px;
    padding: 16px 5px;

    @include tablet-large {
      width: 25%;
      min-height: 330px;
      padding: 16px 8px;
    }
  }
}

.tag-list {
  margin-top: 3px;
  overflow-x: hidden;
}

.reminder {
  text-align: center;
  padding: 5px;
  border-bottom: 2px solid $darkblue;
  max-height: 40px;
  @include for-phone-only {
    font-size: 12px;
    padding: 5px 10px;
  }

  @include for-small-tablet {
    font-size: 15px;
  }
}

.rotate {
  display: none;
  width: 20%;
  height: 20%;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 50%);
}

.default-margin {
  margin: 35px auto;
}

// IMAGE WRAPPER
.image-wrapper {
  width: 100%;
  position: relative;

  &__image {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: calc(100% - 16px);
    left: 8px;
  }
}

.sixteen-nine {
  &::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
}

.four-three {
  &::before {
    content: "";
    display: block;
    padding-top: 75%;
  }
}

.square {
  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.contain {
  background-size: contain !important;
}

.cover {
  background-size: cover !important;
}

.video {
  height: 100%;
  width: 100%;
}

.video-cover {
  object-fit: cover;
}

// New design styles
ul.filters {
  display: flex;
  list-style-type: none;
  padding: 0 15px 32px;
  margin: 0;
  white-space: nowrap;
  overflow: auto;
  width: 100%;
  justify-content: center;
  gap: 40px;

  @include tablet {
    gap: 80px
  }

  @include tablet-large {
    white-space: normal;
    gap: 100px;
  }

  > li {
    display: block;
    margin-right: 12px;
    margin-left: 12px;
  }

  .filters__button {
    padding: 0;
    font-size: 16px;
    color: #5e6775;
    background: none;
    border: 0;
    cursor: pointer;

    &.active {
      position: relative;
      font-weight: 700;
      color: $primary-color;

      &:before {
        content: "";
        position: absolute;
        right: 0;
        bottom: -6px;
        left: 0;
        width: 5px;
        height: 5px;
        margin-right: auto;
        margin-left: auto;
        border-radius: 50%;
        background-color: $color-primary;
      }
    }
  }
}

ul.search-type-filters {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding-bottom: 60px;
  margin: 0;
  white-space: nowrap;
  overflow: auto;

  @include tablet-large {
    justify-content: flex-start;
    white-space: normal;
  }

  > li {
    margin-right: 12px;
    margin-left: 12px;
  }

  .filters__button {
    @include tablet-large {
      width: 212px;
    }

    font-family: $font-family-secondary;
    padding: 0;
    font-size: 24px;
    color: $color-black;
    background: none;
    border: 0;
    cursor: pointer;

    &.active {
      position: relative;
      color: $color-black;

      &:before {
        content: "";
        position: absolute;
        right: 0;
        bottom: -6px;
        left: 0;
        width: 5px;
        height: 5px;
        margin-right: auto;
        margin-left: auto;
        border-radius: 50%;
        background-color: $color-black;
      }
    }
  }
}

.section {
  padding-top: 50px;
  padding-bottom: 50px;

  @include tablet {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-promo {
  padding-top: 16px;
  padding-bottom: 16px;

  @include tablet {
    padding-top: 68px;
    padding-bottom: 32px;
  }
}

.section-small {
  padding-top: 16px;
  padding-bottom: 16px;

  @include tablet {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.section-small-medium {
  padding-top: 16px;
  padding-bottom: 32px;

  @include tablet {
    padding-top: 32px;
    padding-bottom: 60px;
  }
}

.section-medium {
  padding-top: 32px;
  padding-bottom: 32px;

  @include tablet {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-head {
  padding-top: 107px;
  padding-right: 15px;
  padding-bottom: 32px;
  padding-left: 15px;

  @include tablet {
    padding-top: 125px;
  }
}

.section-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50vh;
  color: $color-white;

  @media (orientation: landscape) and (max-width: 767px) {
    height: 60vh;
  }

  @include tablet {
    height: 587px;
  }

  &__background-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    video{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    z-index: 1;
    position: relative;

    @include tablet-large {
      padding-top: 40px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 12px;

    @include tablet {
      font-size: 32px;
      margin-bottom: 32px;
    }
  }

  p {
    color: $color-white;
  }
}

.section-hero-notifications {
  display: inline-block;
  vertical-align: middle;
  font-size: 23px;
  padding: 0;
  background: none;
  border: 0;
  color: $color-white;

  [class^="icon-"] {
    padding: 23px 0;
  }

  svg {
    display: block;
    height: 24px;
    stroke: #ffffff;

    .header-fixed &,
    .header-inverse & {
      stroke: #121318;
    }
  }
}

.section-partner-apply {
  padding-top: 125px;

  .h2 {
    margin-bottom: 32px;
  }

  p {
    margin-bottom: 16px;
    font-size: 16px;
    letter-spacing: -0.5px;
    color: #888c9d;
  }
}


.margin-bottom-40{
  margin-bottom: 40px !important;
}