.search-header {
  &__logo {
    margin: 20px auto;
    height: 100px;
    object-fit: cover;
  }
  &__title {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    @include for-phone-only {
      font-size: 20px;
    }
  }
  &__search {
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
    padding-top: 0px;
    margin-left: 10px;

    @include for-phone-only {
      font-size: 18px;
    }
  }
  &__search--for {
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;

    @include for-phone-only {
      font-size: 18px;
    }
  }
}
