// Colors

$white: #ffffff;
$darkblue: #151f6d;
$black: #000000;
$grey: #f2f3f7;

$color-primary: #337ae4; // hyatt blue

// States
$state-disabled-color: #acb9cb;

// Components

// Button
$button-primary-background-color: $color-primary;
$button-primary-active-background-color: #134ea6;
$button-primary-disabled-background-color: $state-disabled-color;
$button-primary-color: $white;
$button-border-radius: 6px;
