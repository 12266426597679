@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(120deg);
	}
	to {
		transform: rotate(120deg);
	}
}

@keyframes pulse {
	from {
		opacity: 1;
	}
	20% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes slideInLeft {
	from {
		left: -700px
	}
	to {
		left: 0px;
	}
}

@keyframes slideOutRight {
	from {
		left: 0px
	}
	to {
		left: -700px;
	}
}