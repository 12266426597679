.mobile-hidden {
  display: block;
  @include for-phone-only {
    display: none;
  }
}

.mobile-only {
  display: none;
  @include for-phone-only {
    display: block;
  }
}

.invisible {
  opacity: 0;
  pointer-events: none;
}

.noscroll {
  overflow: hidden;
}

.center {
  text-align: center;
}

.margin-sides {
  margin-left: 40px;
  margin-right: 40px;
  @include for-phone-only {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.hideOnlyMobile {
  @include for-phone-only {
    display: none;
  }
}

.zero {
  display: none !important;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.d-flex {
  display: flex;
}

.m-auto {
  margin: auto;
}

.full-width {
  width: 100%;
}

// Text helpers
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// Color helpers
.text-error {
  color: $color-danger !important;
}

.text-warning {
  color: #f9851a !important;
}

// Background helpers

.background-default {
  background-color: #fff;
}

.background-muted {
  background-color: $background-color-grey;
}

.background-primary {
  background-color: $color-primary;
}

.background-grey {
  background-color: $background-color-grey;
}

// Decorative styles

.has-overlay {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(22, 27, 34, 0.7);
  }
}

.has-overlay-promo {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.pb0 {
  padding-bottom: 0;
}
