@import "../../../styles/variables.scss";
@import "../../../styles/mixins/breakpoint";

.home-logo-list-wrapper{
  display: flex;
  justify-content: center;
}
.home-logo-list-container{
    padding: 40px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 40px;
    max-width: 1135px;
    .line-1 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .line-2 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
    .group{
      &.group-1{
        width: 100%;
        .group-logos{
          &__logo{
            max-width: calc((100% - 60px * 2) / 3);
          }
          @include tablet{
            &__logo{
              &:nth-of-type(1){
                width: 200px;
                height: 83px;
              }
              &:nth-of-type(2){
                width: 95px;
                height: 57px;
              }
              &:nth-of-type(3){
                width: 150px;
                height: 95px;
              }
              &:nth-of-type(4){
                width: 90px;
                height: 94px;
              }
              &:nth-of-type(5){
                width: 90px;
                height: 95px;
              }
              &:nth-of-type(6){
                width: 128px;
                height: 95px;
              }
            }
          }
        }
      }
      &.group-2{
        flex: 3;
        min-width: 602px;
        @media screen and (max-width: 767px) {
          min-width: 100%;
        }
        .group-logos{
          &__logo{
            max-width: calc((100% - 60px * 2) / 4);
          }
          @include tablet{
            &__logo{
              &:nth-of-type(1){
                width: 69.7px;
                height: 43.5px;
              }
              &:nth-of-type(2){
                width: 126.3px;
                height: 60px;
              }
              &:nth-of-type(3){
                width: 150.3px;
                height: 34.8px;
              }
              &:nth-of-type(4){
                width: 95px;
                height: 30px;
              }
            }
          }
        }
      }
      &.group-3{
        flex: 2;
        min-width: 285.6px;
        @media screen and (max-width: 767px) {
          min-width: 100%;
        }
        .group-logos{
          &__logo{
            &:nth-of-type(1){
              width: 87.1px;
              height: 87.1px;
            }
            &:nth-of-type(2){
              width: 58.5px;
              height: 87.1px;
            }
          }
        }
      }
      &.group-4{
        flex: 1;
        min-width: 207px;
        @media screen and (max-width: 767px) {
          min-width: 100%;
        }
        .group-logos{
          &__logo{
            &:nth-of-type(1){
              width: 144px;
              height: 87.1px;
            }
          }
        }
      }
      .group-name{
        text-transform: uppercase;
        color: $placeholder-color;
        font-size: 12px;
        font-weight: 400;
        display: flex;
        &__text{
          padding: 0 4px;
        }
        &::before{
          content: "";
          flex: 1;
          border-bottom: 1px solid $placeholder-color;
          transform: translateY(-50%);
        }
        &::after{
          content: "";
          flex: 1;
          border-bottom: 1px solid $placeholder-color;
          transform: translateY(-50%);
        }
      }
      .group-logos{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 60px;
        &__logo{
          cursor: pointer;
          filter: grayscale(1);
          transition: all 0.3s ease-in-out;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            cursor: pointer;
            max-width: 100%;
            max-height: 100%;
          }
          &:hover{
            filter: none;
          }
        }
      }
    }
  }