@import "../../../styles/variables";
@import "../../../styles/mixins/breakpoint";

.input {
  padding: $input-padding-vertical $input-padding-horizontal;
  background: none;
  background-color: $input-background-color;
  border: 1px solid $input-border-color;
  border-radius: 5px;
  font-size: $input-font-size;
  line-height: 1.3;
  color: $input-color;

  @include tablet-large {
    max-width: 100%;
  }

  &::placeholder {
    color: $placeholder-color;
  }

  &:hover {
    border-color: $input-border-color-hover;
  }

  &:focus {
    border-color: $input-border-color-focus;
  }

  &:active {
    border-color: $input-border-color-focus;
  }

  &.error {
    border-color: $input-error-border-color;
    color: $color-danger;
  }

  &.fullwidth {
    width: 100%;
  }

  &:disabled {
    background-color: $input-disabled-background-color;
    border-color: $input-disabled-border-color;
  }

  &.input-lg {
    font-size: 12px;
    letter-spacing: 0.2px;

    @include tablet {
      padding: $input-lg-padding-vertical $input-lg-padding-horizontal;
      font-size: 16px;
    }
  }

  &.input-search {
    .input {
      padding-right: 48px;
      text-overflow: ellipsis;
    }
  }

  &::-ms-expand {
    display: none;
  }
}

input.input-primary {
  border-color: $primary-color;

  + [class^="icon-"] {
    background-color: $primary-color;
    color: $color-white;
  }
}

.input-item {
  position: relative;
  margin-bottom: 10px;

  &.input-icon-left {
    .input {
      padding-left: $input-icon-width;
    }

    [class^="icon-"] {
      left: 0;
    }

    svg,
    img {
      position: absolute;
      top: 10px;
      margin: auto 0;
      left: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;

      @include tablet-large {
        width: 24px;
        height: 24px;
      }
    }
  }

  &.icon-right {
    .input {
      padding-right: $input-icon-width;
    }

    [class^="icon-"] {
      right: 0;
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
    }
  }

  [class^="icon-"] {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $input-icon-width;
    height: 100%;
    font-size: 16px;

    @include tablet-large {
      font-size: 24px;
    }
  }

  .icon-wechat:before {
    content: "\e91a";
    color: rgb(0, 211, 50);
  }

  &.input-search {
    margin-bottom: 12px;

    @include tablet-large {
      margin-bottom: 16px;
    }
  }
}

.input-error-message {
  font-size: 12px;
  line-height: 16px;
  color: $color-danger;
}

.input-search-submit {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  font-size: 24px;
  padding: 0;
  background: none;
  border: 0;
  color: #98a0ab;
  cursor: pointer;

  @include tablet {
    right: 20px;
  }

  &:hover,
  &:focus,
  &:active {
    color: $primary-color;
  }
}

.label {
  display: block;
  margin-bottom: 2px;
  font-weight: 600;
  font-size: 12px;
  color: $label-color;
}

.label-inline {
  font-size: 10px;
  margin-bottom: 8px;

  @include tablet-large {
    margin-bottom: 0;
    padding: 12px 0;
    font-size: 14px;
  }
}
