@import "../../styles/variables.scss";
@import "../../styles/mixins/breakpoint";

.home-category-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1440px;
  margin-right: -5px;
  margin-left: -5px;

  @include desktop {
    margin-right: -8px;
    margin-left: -8px;
  }

  > * {
    width: 20%;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 16px;

    @include tablet {
      padding-right: 8px;
      padding-left: 8px;
      margin-bottom: 32px;
    }
  }
}

.home-category-list-card {
  &__content {
    height: 100%;
    width: 100%;

    @include desktop {
      min-height: 219px;
    }
  }

  &__image {
    position: relative;
    margin-bottom: 12px;

    &:hover {
      .home-category-list-card__icon {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
      }

      @include tablet {
        .home-category-list-card__icon {
          transform: translate(-50%, -50%) scale(1.025);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
          transform-origin: center center;
          transition: all 0.3s;
        }
      }
    }
  }

  &__background {
    display: none;

    @include tablet {
      display: block;
      object-fit: cover;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 50%;
    transition: all 0.3s;

    @include tablet {
      width: 80px;
      height: 80px;
      transition: all 0.3s;
      transform-origin: center center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__icon-image {
    width: inherit;
  }

  &__name {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.4px;
    text-align: center;

    @include tablet {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.input-search-note {
  font-size: 12px;
  letter-spacing: -0.03em;
  line-height: 1.33;

  @include tablet {
    font-size: 18px;
    line-height: 1.38;
  }
}


.home-lifestyle-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 1440px;
  position: relative;
  gap: 2px;

  @include tablet{
    gap: 10px;
  }

  @include desktop {
    gap: 10px;
    margin-right: -8px;
    margin-left: -8px;
  }
}

.news-feed-section {
  padding-top: 20px;
  padding-bottom: 60px;

  @include tablet {
    padding-top: 30px;
    padding-bottom: 80px;
  }
}
.home-lifestyle-list-card-outer{
  position: relative;
  width: calc((100% - 4px) / 3);
  @include tablet{
    width: calc((100% - 20px ) / 3);
  }
}

.home-lifestyle-list-card-wrapper{
  position: relative;
  padding-top: 100%;
}

.home-lifestyle-list-holder{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.home-lifestyle-list-card {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

  @include desktop {
    min-height: 219px;
  }

  &__background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &__play {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    z-index: 10;
    color: $color-white;

    svg {
      position: absolute;
      top: 0;
      left: 4px;
      right: 0;
      bottom: 0;
      margin: auto;
      display: block;
      width: 18px;
      height: 18px;
      fill: $color-white;
      color: $color-white;
    }

    @include tablet {
      width: 100px;
      height: 100px;

      &.icon-play {
        font-size: 40px;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  &__video {
    position: absolute;
    overflow: hidden;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;

    @include tablet {
      height: 100%;
      margin-top: 0;
      object-fit: cover;
    }

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      pointer-events: none;
    }
  }

  .hover-bg {
    display: none;
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(1.42deg, rgba(58, 54, 51, 0.6) 41.45%, rgba(234, 234, 233, 0.24) 77.52%, rgba(255, 102, 0, 0) 99.08%);
  }

  &:hover {
    .hover-bg {
      display: block;
    }
  }

  &__container {
    position: relative;
    padding: 4px;
    width: 100%;
    height: 100%;

    @include tablet{
      padding: 12px;
    }
  }

  &__brand {
    display: flex;
    gap: 8px;
    position: absolute;
    top: 4px;
    left: 4px;

    @include tablet{
      top: 12px;
      left: 12px;
    }
  }


  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    transition: all 0.3s;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid $placeholder-color;

    @include tablet {
      display: none;
    }
  }

  &__icon-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__brand_name {
    color: $color-white;
    font-size: 16px;
    font-weight: 700;
    display: none;

    @include tablet{
      display: block;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  &__name {
    color: $color-white;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include tablet{
      font-size: 18px;
      font-weight: 600;
      min-height: 27px;
    }
  }

  &__date {
    color: $color-white;
    font-size: 6px;
    font-weight: 400;

    @include tablet{
      font-size: 12px;
    }
  }

  .lifestyle-container {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 4px;
    width: 100%;
    overflow: hidden;

    @include tablet{
      padding: 12px;
    }

    .lifestyle-inner{
      position: relative;
      width: 100%;
      display: flex;
      gap: 24px;
      .lifestyle-infos {
        flex: 1;
        overflow: hidden;
      }
      .lifestyle-likes {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @include tablet{
          gap: 2px;
        }
  
        .lifestyle-like {
          font-size: 12px;
          text-align: right;
          background: none;
          border: 0;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          @include tablet{
            font-size: 26px;
          }
  
          .icon-like-fill {
            color: $favorite;
          }
  
          .icon-like {
            &::before { color: $color-white; } 
          }
        }
  
        .lifestyle-like-count {
          color: $color-white;
          font-size: 6px;
          font-weight: 400;
          line-height: 10px;
          height: 10px;
          @include tablet{
            font-size: 10px;
            line-height: 15px;
            height: 15px;
          }
        }
      }
    }

  }
}

.home-logo-list {
  padding: 20px 0;
  display: flex;
  justify-content: center;
}

.overflow-hidden{
  overflow: hidden !important;
}