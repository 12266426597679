@import "../../../styles/variables";
@import "../../../styles/mixins/breakpoint";

.wechat-input-wrapper {
  .input-file {
    width: 100%;
    display: inline-block;
    margin: 0 0 10px;
    position: relative;
    cursor: pointer;
  
    @include tablet {
      width: auto;
    }
  
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 0;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      opacity: 0;
      z-index: 1;
    }
  
    &:hover {
      .input-file-icon {
        color: $blue500;
      }
  
      .input-file-placeholder {
        color: $blue500;
      }
    }
  
    &.fullwidth {
      display: block;
      width: 100%;
    }
  
    &.large,
    &.large.uploaded {
      .input-file-placeholder {
        background-color: $color-white;
        background-image: repeating-linear-gradient(
            0deg,
            rgba(51, 122, 228, 0.5),
            rgba(51, 122, 228, 0.5) 10px,
            transparent 10px,
            transparent 20px,
            rgba(51, 122, 228, 0.5) 20px
          ),
          repeating-linear-gradient(
            90deg,
            rgba(51, 122, 228, 0.5),
            rgba(51, 122, 228, 0.5) 10px,
            transparent 10px,
            transparent 20px,
            rgba(51, 122, 228, 0.5) 20px
          ),
          repeating-linear-gradient(
            180deg,
            rgba(51, 122, 228, 0.5),
            rgba(51, 122, 228, 0.5) 10px,
            transparent 10px,
            transparent 20px,
            rgba(51, 122, 228, 0.5) 20px
          ),
          repeating-linear-gradient(
            270deg,
            rgba(51, 122, 228, 0.5),
            rgba(51, 122, 228, 0.5) 10px,
            transparent 10px,
            transparent 20px,
            rgba(51, 122, 228, 0.5) 20px
          );
        background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
        background-position: 0 0, 0 0, 100% 0, 0 100%;
        background-repeat: no-repeat;
        padding: 10px 26px 10px 8px;
  
        @include tablet {
          padding: 12px;
        }
      }
  
      .input-file-icon {
        padding: 0;
        top: 12px;
        right: 12px;
  
        @include tablet {
          top: 12px;
          right: 12px;
        }
      }
    }
  
    &.uploaded {
      .input-file-icon {
        padding: 0;
        color: $grey-delete;
        z-index: 2;
      }
  
      &:hover {
        .input-file-icon {
          color: $grey-delete;
        }
      }
    }
  }
  
  .input-file-icon {
    position: absolute;
    top: 12px;
    left: auto;
    right: 12px;
    display: block;
    width: 16px;
    height: 16px;
    font-size: 16px;
    line-height: 16px;
    background: none;
    border: none;
    color: $blue400;
    transition: $transition-duration;
  }
  
  .input-file-placeholder {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.3;
    color: #4A4D57;
    background-color: $color-white;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 12px 34px 12px 12px;
    word-break: break-all;
    transition: $transition-duration;
    background-image: none;
    text-overflow:ellipsis;
    overflow:hidden;

    &.inside-formats {
      padding: 4px 34px 4px 12px;

      .input-file-texts {
        color: #888C9D;
        font-size: 14px;
      }

      .input-file-formats {
        color: #888C9D;
        font-size: 12px;
      }
    }
  }
  
  .input-file-formats {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: $grey500;
    margin: 0;
  }  
}
