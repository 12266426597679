.not-available-page {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  text-align: center;

  h1 {
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 16px;
    color: $color-black;

    @include tablet {
      font-size: 100px;
      line-height: 100px;
    }
  }

  h2 {
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;

    @include tablet {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.not-available {
  &__text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);

    font-size: 32px;
    color: $white;
  }
  &__bg-image {
    margin-top: 40px;
    width: 100%;
    height: 100%;
  }
}
