@import "../variables.scss";
@import "../../styles/mixins/breakpoint";

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $title-font-family;
}

.h1 {
  font-family: $font-family-secondary;
  font-size: 32px;
  letter-spacing: -0.2px;
  color: $color-black;

  @include tablet {
    font-size: 62x;
  }
}

.h2 {
  margin-bottom: 32px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.9px;
  color: $color-black;

  @include tablet {
    margin-bottom: 50px;
    font-size: 32px;
  }
}

.h3 {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -0.2px;
}

.h4 {
  font-weight: 600;
  font-size: 20px;
  letter-spacing: -0.2px;
}

.h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
  margin: 0 0 16px;
}

p {
  letter-spacing: -0.3px;
}

.link {
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  color: $color-primary;

  &.link-underline {
    text-decoration: underline;
  }
}

.link-default {
  text-decoration: none;
  color: $color-primary;
}

.link-dot {
  background: none;
  border: 0;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  color: $blue-filter;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: $color-primary;
  }

  &.selected {
    font-weight: 700;
    font-size: inherit;
    position: relative;
    color: $color-primary;

    &:before {
      content: "";
      position: absolute;
      right: 0;
      bottom: -6px;
      left: 0;
      width: 5px;
      height: 5px;
      margin-right: auto;
      margin-left: auto;
      border-radius: 50%;
      background-color: $color-primary;
    }
  }
}

.subtitle {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: $grey-subtitle;
  margin: 0 0 4px;
}

// special title with secondary font family
.title {
  margin-bottom: 24px;
  font-family: $font-family-secondary;
  font-weight: 400;
  line-height: 1.15;
}
