@import "../../../styles/variables.scss";
@import "../../../styles/mixins/breakpoint";

.button {
  display: inline-block;
  min-height: 39px;
  font-family: $button-font-family;
  font-size: 14px;
  padding: 12px 24px;
  font-weight: 600;
  line-height: 1.1;
  text-align: center;
  border-radius: $button-border-radius;
  border-width: 1px;
  border-style: solid;
  transition: all $transition-duration ease-in-out;
  cursor: pointer;
  width: 100%;

  @include tablet {
    padding: 10px 24px;
    width: auto;
  }

  [class^="icon-"] {
    font-size: 16px;
    margin-right: 4px;
  }

  span {
    vertical-align: middle;
  }

  svg {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 5px;
  }

  &:disabled {
    cursor: default;
  }

  &.full {
    width: 100%;
    padding: 12px 24px;
  }

  &-min {
    width: auto;
  }

  &.margin-b {
    margin-bottom: 12px;
  }

  &.margin-r {
    margin-right: 12px;
  }

  &[href] {
    display: inline-block;
    text-decoration: none;
  }

  &-default {
    background-color: $color-white;
    border-color: $color-white;
    color: #202126;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      color: $color-white;
    }
  }

  &-primary {
    background-color: $primary-bckg;
    color: $white-text-color;
    border-color: $primary-bckg;
    width: 100%;

    @include tablet {
      width: auto;
    }

    &:active:not(:disabled) {
      background-color: $primary-active-bckg;
      border-color: $primary-active-bckg;
      transition: background-color $transition-duration ease-in-out;
    }

    &:hover:not(:disabled) {
      box-shadow: 0px 3px 6px 0px $button-box-shadow;
      transition: box-shadow $transition-duration ease-in-out;
    }

    &:disabled {
      background-color: $primary-disabled;
      border-color: $primary-disabled;
    }
  }

  &-secondary {
    background-color: $bkcg-transparent;
    border-color: $secondary-border-color;
    color: $white-text-color;

    &:active:not(:disabled) {
      background-color: $bkcg-transparent;
      transition: background-color $transition-duration ease-in-out;
    }

    &:hover:not(:disabled) {
      background-color: $secondary-hover;
      transition: background-color $transition-duration ease-in-out;
    }

    &:disabled {
      color: $secondary-disabled;
      border-color: $secondary-disabled;
    }
  }

  &-secondary-light {
    background-color: $bkcg-transparent;
    border-color: $primary-border;
    color: $text-color;

    &:active:not(:disabled) {
      border-color: $primary-border;
      transition: all $transition-duration ease-in-out;
      box-shadow: 0px 3px 6px 0px $button-box-shadow;
    }

    &:hover:not(:disabled) {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $color-white;
      transition: all $transition-duration ease-in-out;
    }

    &:focus:not(:disabled) {
      box-shadow: 0px 3px 6px 0px $button-box-shadow;
      transition: all $transition-duration ease-in-out;
    }

    &:disabled {
      color: $primary-disabled;
      border-color: $primary-disabled;
    }
  }

  &-primary-dark {
    background-color: $primary-dark;
    color: $white-text-color;
    border-color: $primary-dark;

    &:active:not(:disabled) {
      background-color: $primary-dark;
      border-color: $primary-dark;
      transition: all $transition-duration ease-in-out;
    }

    &:hover:not(:disabled) {
      box-shadow: 0px 3px 6px 0px $button-box-shadow;
      transition: box-shadow $transition-duration ease-in-out;
    }

    &:disabled {
      background-color: $primary-dark-disabled;
      border-color: $primary-dark-disabled;
    }
  }
}

.button-play {
  &:hover:not(:disabled) {
    box-shadow: 0px 3px 6px 0px $button-box-shadow;
    transition: box-shadow $transition-duration ease-in-out;
  }

  &:active:not(:disabled) {
    box-shadow: none;
    color: $blue500;
    transition: color $transition-duration ease-in-out;
  }

  &:disabled,
  &.disabled {
    background-color: #f8f9fa;
    color: $blue100;
    pointer-events: none;
  }
}

.button-full-screen {
  cursor: pointer;
}

